import React from 'react'
import calendar from './img/calendar.png'
import solid from './img/link-solid.svg'
import newicon from './img/newicon.gif'
import pdf from './img/pdf.png'
import EXAMINATION_CENTRES from '../../pdf/EXAMINATION_CENTRES.pdf'
import IMPORTANT_DATES from '../../pdf/IMPORTANT_DATES.pdf'
import IMPORTANT_INSTRUCTIONS from '../../pdf/IMPORTANT_INSTRUCTIONS.pdf'
import IMPORTANT_INSTRUCTIONS_NRI from '../../pdf/IMPORTANT_INSTRUCTIONS_NRI.pdf'
import PROGRAMS_AVAILABLE from '../../pdf/PROGRAMS_AVAILABLE.pdf'
import information_brrochure from '../../pdf/AICEE 2023 INFORMATION BROCHURE.pdf'
import {Link} from 'react-router-dom'

function LeftBar() {
  return (
    <div>
        <div className="boxdesignCurrentEvents">



<h5 className="currentEventHeading" style={{ display: 'flex' }}>
    Current Events
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <img src={calendar}
        width="10%" />
</h5>
<nav id="sidebar">
    <ul className="list-unstyled components">
        <li style={{ listStyle: 'none' }} className="CE_List">
            <Link to="/about"  className="parentLink">About AICEE </Link>
        </li>
        <li style={{ listStyle: 'none' }} className="CE_List">
            <a href={IMPORTANT_DATES} target='_black'  className="parentLink"> Important Dates
            </a>
        </li>
        <li style={{ listStyle: 'none' }} className="CE_List nav-item dropdown">
            <Link className="parentLink nav-link  " id="navbardrop" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                to='/syllabous'>Syllabus&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Link>
            <div className="dropdown-menu" aria-labelledby="navbardrop" id="myDropdown">
                <a className="dropdown-item sublink" href="doc/SYLLABUS FOR 10TH LEVEL STUDENT.pdf"
                    > 10th </a>
                <a className="dropdown-item sublink" href="doc/SYLLABUS FOR 12TH LEVEL STUDENT.pdf"
                    > 12th </a>
                <a className="dropdown-item sublink" href="doc/gradsyllabus.pdf" > Graduation
                </a>
            </div>
        </li>
        {/* <li style={{ listStyle: 'none' }} className="CE_List">
            <Link to="/sample-question-paper-2023"  className="parentLink"> AICEE Sample Question Papers
                2023 (Phase 1). </Link>
        </li> */}
        <li style={{ listStyle: 'none' }} className="CE_List">
            <Link to={EXAMINATION_CENTRES} target='_black'  className="parentLink"> Exam centre ( For
                Centre Based Examination Mode Only ) </Link>
        </li>
        <li style={{ listStyle: 'none' }} className="CE_List">
            <Link to={IMPORTANT_INSTRUCTIONS} target='_blank'  className="parentLink">Important
                Instructions </Link>
        </li>
        <li style={{ listStyle: 'none' }} className="CE_List">
            <Link to={IMPORTANT_INSTRUCTIONS_NRI} target='_blank'  className="parentLink">Important Instructions for NRI / Foreign Candidates </Link>
        </li>
    </ul>
</nav>
</div>
<div className="boxdesignImpLinks">
<h5 className="impHeading">
    Important Links&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <img src={solid} width="5%" />
</h5>
<nav id="sidebar">
    <ul className="list-unstyled components">

        {/* <li style={{listStyle: 'none'}} >
            <a style={{display:'flex',justifyContent:'space-around'}}  href="/refund.php" target='blank'>AICEE Refund Request Application. <sup><img
                src={newicon} alt='new' /></sup></a>
        </li> */}

        {/* <li style={{ listStyle: 'none' }}>
            <a href="/doc/Dates & Instructions.pdf" target='blank'>Counselling Update : Dates &
                Instructions.(Click here)</a>
        </li> */}

        {/* <li style={{ listStyle: 'none' }}>
            <a href="https://counselling.aicee.in" target='blank'>AICEE Phase 1&2 Result Link.(click
                here)</a>
        </li> */}

        <li style={{ listStyle: 'none' }}>
            <a style={{display:'flex',justifyContent:'space-around'}} href={PROGRAMS_AVAILABLE} target="_blank" 
                className="parentLink">PRORGRAMS OFFERED THROUGH AICEE - 2024 (LEVEL WISE) <sup><img
                    src={pdf} style={{ width: "20px" }} /></sup></a>
        </li>
        <li style={{ listStyle: 'none' }}>
            <a style={{display:'flex',justifyContent:'start'}} href={information_brrochure} target="_blank" 
                className="parentLink">Information Brochure 2024 <sup><img
                    src={pdf} style={{ width: "20px" }} /></sup></a>
        </li>
        {/* <li style={{ listStyle: 'none' }}>
            <a style={{display:'flex',justifyContent:'start'}} href="https://counselling.aicee.in/spot/student/login" target="_blank" 
                className="parentLink">AICEE 2023 Spot Round Counseling Portal </a>
        </li> */}

    </ul>
</nav>
</div>
    </div>
  )
}

export default LeftBar