import React,{useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
const Nri = () => {
    const navigate = useNavigate()
    useEffect(()=>{
       setTimeout(()=>{
        alert('NRI / Foreign Candidates Applications are closed for Phase 3')
        navigate('/')
       },2000)
    })
  return (
    <>

    </>
  )
}

export default Nri