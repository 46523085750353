import React,{useState,useEffect,useRef} from 'react'
// import UpperNavbar from '../Dashboard/UpperNavbar';
import UpperNavbar from '../../Dashboard/UpperNavbar';
import {useNavigate} from 'react-router-dom'
import { useReactToPrint } from "react-to-print";
import AcknowledgeMentPrint from './AcknowledgeMentPrint';
import { MDBIcon, MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody } from 'mdb-react-ui-kit'
function PaymentThankYou() {
    const navigate = useNavigate()
    const [studentData, setStudentData] = useState({})
    const [paymentDate, setPaymentDate] = useState('')
    const [paymentMethod, setPaymentMethod] = useState('')
    const [amount, setAmount] = useState('')
    const [transactionId, setTransactionId] = useState('')
    const [status, setStatus] = useState('')
    const [optSmModal, setOptSmModal] = useState(false);
    const toggleShow1 = () => setOptSmModal(!optSmModal);
    //        USER AUTHENTICATION            //
  const userAuthenticationData = async () => {
    fetch('/user-authentication-data')
      .then(response => response.json())
      .then(data => {
        setStudentData(data)
        if (data.paymentResponsePCB) {
            const payment = data.paymentResponsePCB[data.paymentResponsePCB.length - 1].paymentStatus
            const paymentTrim = payment.trim().split("&")
            const transDate = paymentTrim[40].trim().split('=')
            const paymentMode = paymentTrim[5].trim().split('=')
            const paymentAmount = paymentTrim[10].trim().split('=')
            const paymentTransaction = paymentTrim[1].trim().split('=')
            const orderStatus = paymentTrim[3].trim().split('=')
            setPaymentDate(transDate[1])
            setPaymentMethod(paymentMode[1])
            setAmount(paymentAmount[1])
            setTransactionId(paymentTransaction[1])
            setStatus(orderStatus[1])
            // console.log(paymentTrim)
            // console.log(transDate)
  
  
  
          }
          else {
            // console.log('no')
          }
        
      })
      .catch(error => {
        console.log(error)
      })
  }

    ///   print functionallity
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
    //   pageStyle: "@page { margin-top: 0px;  } ",
      content: () => componentRef.current,
    });

      //    MBA APPLICATION OPEN FUNCTIONALLITY
  const [fullscreenXlModal, setFullscreenXlModal] = useState(false);

  const toggleShow = () => setFullscreenXlModal(!fullscreenXlModal);
  useEffect(() => {
    userAuthenticationData()
  }, [])

  return (
    <div className='paymentPage'>
        <div>
            <UpperNavbar/>
        </div>
        <div>
            <button onClick={()=>{navigate('/student-dashboard')}} className='m-4 border border-b-slate-400 pl-3 pr-3 pt-2 pb-2 font-bold' style={{borderRadius:'7px'}}>Back To Dashboard</button>
        </div>
        <div>
            <h1 className='text-center text-6xl'>Thank You !</h1>
            <p className='text-center text-2xl'>Your application has been Submitted Successfully</p>
            <p className='text-center text-xl'>Application No : <span className='font-bold'>{studentData.PCBApplicationNo}</span></p>
            <p className='text-center text-xl'>Payment Transaction ID : <span className='font-bold'>{transactionId}</span></p>
            <p onClick={toggleShow1}  className='text-center text-sm pt-4 cursor-pointer'> <span><i className='fa fa-print'/></span> Print / Download Application Form <span className='font-bold'></span></p>
            <p onClick={() => { setFullscreenXlModal(!fullscreenXlModal) }} className='text-center text-sm pt-1 cursor-pointer'> <span><i className='fa fa-print'/></span> Print / Download Payment Acknowledgement<span className='font-bold'></span></p>
            {/* <p onClick={() => { setFullscreenXlModal(!fullscreenXlModal) }} className='text-center text-sm pt-4 cursor-pointer'> <span><i className='fa fa-print'/></span> Print / Download Application Form<span className='font-bold'></span></p> */}
        </div>
        {/* // modal start // */}
        <MDBModal tabIndex='-1' show={fullscreenXlModal} setShow={setFullscreenXlModal}>
            <MDBModalDialog size='fullscreen-xxl-down'>
              <MDBModalContent>
                <MDBModalBody>
                  <div style={{ width: '700px', margin: 'auto' }} className='border border-black' ref={componentRef}>
                    <div className='m-5 border border-black'>
                      <div className='m-4'>
                        <img style={{ width: '11rem', margin: 'auto' }} src="https://ucarecdn.com/310f986c-7286-41bf-9bfd-79673ec9eff1/AICEE2023LOGO4.png" alt="" />
                      </div>
                      <div className='text-center text-black'>
                        <h5 >All India Combined Entrance Examintaion</h5>
                      </div>
                      <div className='text-center text-black font-bold '>
                        <h5 className='font-bold' style={{ marginTop: '1.3rem', textDecoration: 'underLine' }}>Electronic Receipt</h5>
                      </div>
                      <div className='text-center text-black font-bold '>
                        <h6 className='' style={{ marginTop: '1.3rem' }}>Application No : <span className='font-bold'>{studentData.PCBApplicationNo}</span></h6>
                      </div>
                      <div className='text-start pl-3 text-black mt-1' style={{ fontSize: '0.9rem' }}>
                        <p className='' style={{ marginTop: '1.8rem' }}>To  <span className='font-bold'>{studentData.studentName}</span></p>
                        <p className='font-bold' style={{ marginTop: '-0.8rem', fontSize: '0.8rem' }}>Online Application successfully Submitted. </p>
                        <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem' }}>Amount Received towards application for <span className='font-bold'>UG Application Form 2023 <br />B.Pharmacy , Bsc.Nursing , Bsc.Agriculture , Bio-Tech ( Bsc.BioTech / BioTech)</span> </p>
                        <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem' }}>Date of Receipts : <span className='font-bold'>{paymentDate}</span> </p>
                        <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem' }}>Payment Type : <span className='font-bold'>Online</span> </p>
                        <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem' }}>Application Fee : <span className='font-bold'>{amount}</span> </p>
                        <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem', marginBottom: '3rem' }}>Transaction Id : <span className='font-bold'>{transactionId}</span> </p>
                      </div>
                    </div>
                  </div>
                  <center className='pb-5 mt-2'>
                    <MDBBtn onClick={() => { setFullscreenXlModal(!fullscreenXlModal) }} color='success'>Close</MDBBtn>
                    <MDBBtn className='ml-3' color='primary' onClick={handlePrint}>Print</MDBBtn>
                  </center>
                </MDBModalBody>
                {/* <MDBModalFooter> */}
                {/* </MDBModalFooter> */}
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        {/* // modal end // */}
        <MDBModal show={optSmModal} tabIndex='-1' setShow={setOptSmModal}>
        <MDBModalDialog size='lg'>
          <MDBModalContent>
            <MDBModalHeader>
              {/* <MDBModalTitle className='text-black font-bold'>Preview Of Your Application</MDBModalTitle> */}
              <MDBBtn className='btn-close' color='none' onClick={toggleShow1}></MDBBtn>
            </MDBModalHeader>
            
            <MDBModalBody>
             
                   <AcknowledgeMentPrint studentData={studentData}/>  
                 
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  )
}

export default PaymentThankYou