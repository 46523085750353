import React from 'react'
import { MDBIcon } from 'mdb-react-ui-kit'
import { useNavigate,Link } from 'react-router-dom'
const ApplicationControl = ({percentage, eligibility, title, applicationNo, application, isApplicationCompleted,route,applicationTitle }) => {

    const navigate = useNavigate()

    const applicationFormTitle = applicationTitle.replace(/ /g, "-")
    const applicationRoute =  `${route}-${applicationNo}-${applicationFormTitle}`

    return (
        <div>
            <table className='bg-white mt-2' style={{ width: '99%', marginLeft: '5px' }}>
                <tr>
                    <th style={{ border: '1px solid #bdc2c7', borderLeft: '5px solid #042e2e', width: '36%', padding: '0.5rem 1rem', fontSize: '13px', color: 'black', fontWeight: 'bolder' }} className='font-bold text-black'>{title} </th>
                    <th style={{ border: '1px solid #bdc2c7', width: '16%', textAlign: 'center', padding: '0.5rem 1rem', fontSize: '13px', color: 'black' }}>{applicationNo}</th>
                    <th style={{ border: '1px solid #bdc2c7', width: '16%', textAlign: 'center', padding: '0.5rem 1rem', fontSize: '13px', color: 'black' }}> <span className='font-bold'>₹</span> 1300.00</th>
                    <th style={{ border: '1px solid #bdc2c7', width: '16%', textAlign: 'center', padding: '0.5rem 1rem', fontSize: '13px', color: 'black' }}> <a target="__blank" href={eligibility}><MDBIcon className='text-red-700 text-3xl' far icon="file-pdf" /></a> </th>
                    {
                        isApplicationCompleted ? <>

                            <th style={{ border: '1px solid #bdc2c7', width: '16%', textAlign: 'center', padding: '0.5rem 1rem', fontSize: '13px', color: 'black' }}><button type="button" style={{ backgroundColor: "#042e2e", color: 'white', textTransform: 'capitalize', fontSize: '0.8rem' }} className="btn"><Link to={`/student-dashboard/${route}/form`} target="_blank" style={{textDecoration:'none',color:'white'}}>Download Application</Link></button>
                            </th>

                        </> :
                            <>
                                {
                                    applicationNo ?
                                        <th style={{ border: '1px solid #bdc2c7', width: '16%', textAlign: 'center', padding: '0.5rem 1rem', fontSize: '13px', color: 'black' }}><button type="button" style={{ backgroundColor: "#042e2e", color: 'white', textTransform: 'capitalize', fontSize: '0.8rem' }} class="btn" onClick={() => { navigate(`/student-dashboard/${route}-${applicationNo}`) }}>Continue</button>
                                            <span style={{ fontWeight: '400', fontSize: '' }}>Application is <span className='font-bold'>{percentage } %</span> Completed</span>
                                        </th>
                                        :
                                        <th style={{ border: '1px solid #bdc2c7', width: '16%', textAlign: 'center', padding: '0.5rem 1rem', fontSize: '13px', color: 'black' }}><button type="button" style={{ backgroundColor: "#042e2e", color: 'white', textTransform: 'capitalize', fontSize: '0.8rem' }} class="btn" onClick={() => { application(route) }}>Apply Now</button>
                                        </th>
                                }
                            </>
                    }
                </tr>
            </table>
        </div>
    )
}

export default ApplicationControl