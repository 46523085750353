import React,{useState,useEffect} from 'react'
// import Navbar from '../../Components/Dashboard/Navbar'
import Step1 from '../../Components/applicationStages/Step1'
import Step2 from '../../Components/applicationStages/Step2'
import Step3 from '../../Components/applicationStages/Step3'
import Step4 from '../../Components/applicationStages/Step4'
import Step5 from '../../Components/applicationStages/Step5'
import UpperNavbar from '../Dashboard/UpperNavbar'

import { useParams } from 'react-router-dom'
const MbaApplication = () => {
    const {application} = useParams()
    const title = application.split("-")[0]
    const [applicationTitle,setApplicationTitle] = useState("")
    const [studentData, setStudentData] = useState({})
    const [activeStep, setActiveStep] = useState(1)
    const userAuthenticationData = async () => {
        fetch('/user-authentication-data')
            .then(response => response.json())
            .then(data => {
                setStudentData(data)
            })
            .catch(error => {
                console.log(error)
            })
    }
    useEffect(() => {
        userAuthenticationData()
        if(title == "mba"){
            setApplicationTitle("PG Application Form 2024 (MBA/PGDM)")
        }
        if(title == "engg"){
            setApplicationTitle("UG Application Form 2024 Engineering")
        }
        if(title == "pcm"){
            setApplicationTitle("UG Application Form 2024 PCM")
        }
        if(title == "pcb"){
            setApplicationTitle("UG Application Form 2024 PCB")
        }
        if(title == "other"){
            setApplicationTitle("UG Application Form 2024 Other")
        }
    }, [])
    return (
        <div>
            <UpperNavbar data={studentData} />
            {
                activeStep == 1 && <Step1 title={applicationTitle} data={studentData} setActiveStep={setActiveStep}/>
            }
            {
                activeStep == 2 && <Step2 getUpdate={userAuthenticationData} title={applicationTitle} data={studentData} setActiveStep={setActiveStep}/>
            }
            {
                activeStep == 3 && <Step3 getUpdate={userAuthenticationData} title={applicationTitle} data={studentData} setActiveStep={setActiveStep}/>
            }
            {
                activeStep == 4 && <Step4 getUpdate={userAuthenticationData} route={title} title={applicationTitle} data={studentData} setActiveStep={setActiveStep}/>
            }
            {
                activeStep == 5 && <Step5 getUpdate={userAuthenticationData} route={title} title={applicationTitle} data={studentData} setActiveStep={setActiveStep}/>
            }
            
        </div>
    )
}

export default MbaApplication