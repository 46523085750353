import React from 'react'

import Header from './Header'
import Marque from './Marque'
import {Link} from 'react-router-dom'
function About() {
    return (
        <div >
            <Header/>
            <Marque/>
            <section className="contentsection mt-2 text-black ">
                <div className="container">
                    <div className="row">
                        {/* LEFT SIDEBAR START HERE */}
                        {/* LEFT SIDEBAR END HERE */}
                        {/* INTRO SECTION START HERE */}
                        {/* INTRO SECTION START HERE */}
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pagecontentpara LandingPara">
                            <div className="mb-4">
                                <div className="mb-4">
                                    <h5 className="pageheading text-center text-black text-sm">
                                        About AICEE (ALL INDIA COMBINED ENTRANCE EXAMINATION)
                                    </h5>
                                    <p align="justify" >
                                    AICEE (All India Combined Entrance Examination ) is a national level combined entrance examination which is
conducted with an association of 1200+ Colleges and 250 + Universities of India for admission in Different
Undergraduate and Postgraduate programs that they are offering through one common application . AICEE
Examination is Designed in such a way that after appearing in AICEE Examination students don&#39;t have to appear for
multiple examinations of different states and Universities .
                                    </p>
                                    <p align="justify" style={{fontSize:'13px',color:'black',fontWeight:'400'}}>
                                    AICEE 2024 Examination will be conducted Online in Two different Modes : First one is Home Based Examination
Mode ( In this mode Students can appear for AICEE Examination by just sitting at their Home using their Laptop ,
Desktop , Tablets and Smartphones , Students can even choose their Examination Slot &amp; Timings at the time of
Application ) . Second Mode is Centre Based Online Examination Mode ( In this mode students can appear for
AICEE Examination By Selecting their examination city ) .
                                    </p>
                                    <p align="justify" style={{fontSize:'13px',color:'black',fontWeight:'400'}}>
                                    Entire process of AICEE begins from Application, Examination , College choice filling till college reporting will be
done Online so students don&#39;t have to travel much. This Will save a lot of time, effort &amp; money . AICEE Will also
provide Rank wise fees waiver for meritorious students . No other Examination in India will give this much range of
College through one single Application . There are many more advantages of AICEE.
                                    </p>
                                    <p align="justify" style={{fontSize:'13px',color:'black',fontWeight:'400'}}>
                                    All these Advantages make AICEE a unique and Must Apply Examination to get admission in 1200 + Colleges &amp;
250+ Universities of India for more than 20 programs.
                                    </p>
                                    <p /><span style={{fontSize:'13px',color:'black',fontWeight:'400'}}> For Engineering Admission in Maharashtra, Odisha and West Bengal students have to appear in JEE ( Mains )
alongside AICEE. This Examination is organized by AICEE (All India Combined Entrance Examination) A Unit of
INFOMAX AVENUES EDUCON INDIA PVT LTD.  Students have to follow all the rules &amp; Regulation of respective
Institution before &amp; After their Final Allotment.
                                    </span>
                                    <b>
                                        <p /> <h5 className='font-bold'>PRORGRAMS OFFERED THROUGH AICEE - 2024 (LEVEL WISE)</h5>
                                    </b>
                                    <table className="table table-hover text-black">
                                        <tbody>
                                           
                                        <tr>
                                                <th scope="row" className='font-bold text-black'>12th Level (Engineering)</th>
                                                <td style={{fontSize:'13px',color:"black"}}>
                                                Engineering (B-Tech / BE)
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className='font-bold text-black'>12th Level (PCM)</th>
                                                <td style={{fontSize:'13px',color:"black"}}>
                                                Bio Technology ( Bsc. BioTechnology / B-Tech BioTechnology ) , B.Pharmacy , Agriculture ( Bsc. Agriculture / B-Tech Agriculture ) , Bsc Datascience
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">12th Level (PCB)</th>
                                                <td style={{fontSize:'13px',color:"black"}}>
                                                B.Pharmacy , Bsc.Nursing , Bsc.Agriculture , Bio-Tech 
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row" className='font-bold text-black'>12th Level (Other Stream like Commerce, Arts & other Subjects.)</th>
                                                <td style={{fontSize:'13px',color:"black"}}>
                                                BBA , BCA , B.Design , LLB (BA / B.Com / B.sc / BBA / BCA) , Mass Communication & Journalism
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Grad Level </th>
                                                <td>MBA/PGDM </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="mt-2">
                                <nav id="sidebar">
                                    <div className="text-center">
                                        <Link to="/">
                                            <button className="btn btn-primary btn-sm">
                                                Back To Home Page
                                            </button>
                                        </Link>
                                    </div>
                                </nav>
                                <br />
                            </div>
                        </div>
                        {/* INTRO SECTION END HERE */}
                        {/* INTRO SECTION END HERE */}
                        {/* RIGHT SIDEBAR START HERE */}
                        {/* RIGHT SIDEBAR END HERE */}
                    </div>
                </div>
            </section>
        </div>

    )
}

export default About