import React, { useState, useEffect } from 'react'
import { MDBIcon } from 'mdb-react-ui-kit'
import { useNavigate } from 'react-router-dom'
import { Widget } from '@uploadcare/react-widget'
import Preview from './Preview'
// import AcknowledgeMentPrint from './AcknowledgeMentPrint'
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody
} from 'mdb-react-ui-kit';
import Qualification from './Qualification';
function Step5({ data, setActiveStep,title,route,getUpdate }) {
  const navigate = useNavigate()
  const [photo, setPhoto] = useState('')
  const [signature, setSignature] = useState('')
  const [updatedList, setUpdatedList] = useState(false)

  const [tenthBoard, setTenthBoard] = useState('')
  const [twelveBoard, setTwelveBoard] = useState('')
  const [gradBoard, setGradBoard] = useState('')
  const [tenthTotal, setTenthTotal] = useState('')
  const [twelveTotal, setTwelveTotal] = useState('')
  const [gradTotal, setGradTotal] = useState('')
  const [tenthObtained, setTenthObtained] = useState('')
  const [twelveObtained, setTwelveObtained] = useState('')
  const [gradObtained, setGradObtained] = useState('')
  const [checked, setChecked] = useState(false)
  const [preview, setPreview] = useState(false)
  const [optSmModal, setOptSmModal] = useState(false);
  const toggleShow = () => setOptSmModal(!optSmModal);
  // console.log(checked)

  //        USER AUTHENTICATION            //
  const userAuthenticationData = async () => {
    fetch('/user-authentication-data')
      .then(response => response.json())
      .then(data => {
       
        if (data.tenthBoard) {
          setPhoto(data.photo)
          setSignature(data.signature)
          setGradBoard(data.gradBoard)
          setTenthBoard(data.tenthBoard)
          setTwelveBoard(data.twelveBoard)
          setTenthObtained(data.tenthObtained)
          setTwelveObtained(data.twelveObtained)
          setGradObtained(data.gradObtained)
          setTenthTotal(data.tenthTotal)
          setTwelveTotal(data.twelveTotal)
          setGradTotal(data.gradTotal)
        }
        else {

        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  ///       EDUCATION DETAILS
  const [tenthSchool,setTenthSchool] = useState('')
  const [tenthResultStatus,setTenthResultStatus] = useState("")
  const [tenthYOP, setTenthYOP] = useState("")
  const [tenthPer, setTenthPer] = useState("")
  const [twelveSchool, setTwelveSchool] = useState("")
  const [twelveResultStatus, setTwelveResultStatus] = useState("")
  const [twelveYOP, setTwelveYOP] = useState("")
  const [twelvePer, setTwelvePer] = useState("")
  const [gradSchool, setGradSchool] = useState("")
  const [gradResultStatus, setGradResultStatus] = useState("")
  const [gradYOP, setGradYOP] = useState("")
  const [gradPer, setGradPer] = useState("")
  
  const onchange = {setTenthSchool,setTenthResultStatus,setTenthYOP,setTenthPer,setTenthBoard,setTwelveBoard,setTwelveResultStatus,setTwelveSchool,setTwelveYOP,setTwelvePer,setGradSchool,setGradResultStatus,setGradYOP,setGradPer,setGradBoard,setPhoto,setSignature}
 
  const value = {
    tenthSchool,tenthBoard,tenthResultStatus,tenthYOP,tenthPer,twelveSchool,twelveBoard,twelveResultStatus,twelveYOP,twelvePer,gradSchool,gradBoard,gradResultStatus,gradYOP,gradPer,photo,signature
  }
  async function saveEducationDetails(event) {
    event.preventDefault()
    await fetch('/api/v2/education-details', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        route,
        tenthSchool,tenthBoard,tenthResultStatus,tenthYOP,tenthPer,twelveSchool,twelveBoard,twelveResultStatus,twelveYOP,twelvePer,gradSchool,gradBoard,gradResultStatus,gradYOP,gradPer,photo,signature,route
      })
    })
      .then((response) => response.json())
      .then((data) => {
       if(data.success){
        alert(data.message)
        getUpdate()
       }
       else{
        alert(data.message)
       }
      })

  }
  async function saveEducationDetailsUpdate(id) {
    
    await fetch(`/api/v2/education-details/${data.educationDetails._id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        route,
        tenthSchool,tenthBoard,tenthResultStatus,tenthYOP,tenthPer,twelveSchool,twelveBoard,twelveResultStatus,twelveYOP,twelvePer,gradSchool,gradBoard,gradResultStatus,gradYOP,gradPer,photo,signature,route
      })
    })
      .then((response) => response.json())
      .then((data) => {
       if(data.success){
        alert(data.message)
        getUpdate()
       }
       else{
        alert(data.message)
       }
      })

  }
  //       UPDATE PHOTO AND SIGNATURE OF USER
  async function updatePhotoSignature() {
    // event.preventDefault()

    const response = await fetch('/document-update', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId: data._id,
        photo,
        signature
      })
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        if (data.message == "changes successfully") {
          // alert('changes successfully')
          // setCentredModal(false)
          navigate('/fillApplication-step6')
        }
        else {
          alert('Error')
          // setCentredModal(false)
        }
      })
  }
  //   DOCUMENT UPLOAD THROUGH UPLOADCARE.Com

  // PHOTO
  const uploadFileChange = (info) => {
    if (info.size <= 100000) {
      setPhoto(info.cdnUrl)
    }
    else {
      alert('Please Upload a lesser image')
    }
  }
  const uploadFileSelect = (file) => {
    console.log(`file changed ${file}`)
    setUpdatedList(false)
    if (file) {
      file.progress((info) => console.log("file progress: ", info.progress))
      file.done((info) => setUpdatedList(true))
    }
  }
  // SIGNATURE
  const uploadFileChange2 = (info) => {


    if (info.size <= 50000) {
      setSignature(info.cdnUrl)
    }
    else {
      alert('Please Upload a lesser image')
    }
  }
  const uploadFileSelect2 = (file) => {
    console.log(`file changed ${file}`)
    setUpdatedList(false)
    if (file) {
      file.progress((info) => console.log("file progress: ", info.progress))
      file.done((info) => setUpdatedList(true))
    }
  }


  // const nextStep = () => {
  //   if (data.city) {
  //     setActiveStep(5)
  //   }
  // }
  useEffect(() => {
   
   
    if (data.educationDetails) {
      setTenthSchool(data.educationDetails.tenthSchool)
      setTenthResultStatus(data.educationDetails.tenthResultStatus)
      setTenthYOP(data.educationDetails.tenthYOP)
      setTenthPer(data.educationDetails.tenthPer)
      setTenthBoard(data.educationDetails.tenthBoard)
      setTwelveBoard(data.educationDetails.twelveBoard)
      setTwelveResultStatus(data.educationDetails.twelveResultStatus)
      setTwelveSchool(data.educationDetails.twelveSchool)
      setTwelveYOP(data.educationDetails.twelveYOP)
      setTwelvePer(data.educationDetails.twelvePer)
      setGradSchool(data.educationDetails.gradSchool)
      setGradResultStatus(data.educationDetails.gradResultStatus)
      setGradYOP(data.educationDetails.gradYOP)
      setGradPer(data.educationDetails.gradPer)
      setGradBoard(data.educationDetails.gradBoard)
      setPhoto(data.educationDetails.photo)
      setSignature(data.educationDetails.signature)
    }
  }, [data])
  return (
    <div className='paymentPage'>
      <h2 className='applicationCSS4 text-center pt-2 text-black font-bold'>{title}</h2>
      <div className='applicationCSS3'>
        <img className='fluid applicationCSS2' style={{ margin: 'auto' }} src="https://ucarecdn.com/90cfcddb-69e6-4c92-9f35-efc371050bd7/step5.png" alt="" />
      </div>
      {/* ********************************   form                **************************** */}
      <div className='p-4 bg-white bootstrap-form applicationCSS'>
        <form className="row g-3">
          <Qualification data={data} onchange={onchange} value={value} route={route}/>
          <p className='mb-[-50px] text-center font-bold text-red-600'>Declaration</p>
          <center className=''>&nbsp; &nbsp;<p className='text-justify text-[12px] text-black font-bold' style={{lineHeight:'-15px'}}><input value={checked} onChange={(e) => { setChecked(!checked) }} className='' type="checkbox" /> I do hereby declare that All the Information provided by me here are true and correct to the best of my knowledge, I do Further undertake that in case any of the Information provided found to be Incorrect at any stage. My Application can be cancelled, I also Agree to Abide by the rules of the Institution. In case of Indiscipline or Disobedience of Rules, I shall be Liable for Expelation from the Institute, the Organisation has the right to use Information for communication purposes.</p></center>
          {/* </div> */}
        </form>
        {/* //////                 PAYMENT PAGE START                          */}
        <form method="POST" name="customerData" action="/ccavRequestHandler">
          <table style={{ display: 'none' }} width="40%" height="100" border='1' align="center">
            <caption>
              <font size="4" color="blue"><b>Integration Kit</b></font>
            </caption>
          </table>
          <table width="40%" height="100" border='1' align="center" style={{ border: 'none' }}>
            <tr style={{ display: 'none' }}>
              <td>Parameter Name:</td>
              <td>Parameter Value:</td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td colspan="2">Compulsory information</td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Merchant Id</td>
              <td><input type="text" name="merchant_id" id="merchant_id" value="241419" /> </td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Order Id</td>
              <td><input type="text" name="order_id" value={data[route].applicationNo} /></td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Currency</td>
              <td><input type="text" name="currency" value="INR" /></td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Amount</td>
              <td><input type="text" name="amount" value="1300" /></td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Billing Address:</td>
              <td><input type="text" name="billing_address"
                value={data.personalDetails.addressLine1} /></td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Billing City:</td>
              <td><input type="text" name="billing_city" value={data.personalDetails.city} />
              </td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Billing State:</td>
              <td><input type="text" name="billing_state" value={data.personalDetails.state} />
              </td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Billing Zip:</td>
              <td><input type="text" name="billing_zip" value={data.personalDetails.pincode} /></td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Billing Country:</td>
              <td><input type="text" name="billing_country" value="India" />
              </td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Billing Tel:</td>
              <td><input type="text" name="billing_tel" value={data.mobileNo} />
              </td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Redirect URL</td>
              <td><input type="text" name="redirect_url"
                value={`https://aicee.in/ccavResponseHandler${route}`} />
              </td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Cancel URL</td>
              <td><input type="text" name="cancel_url"
                value={`https://aicee.in/ccavResponseHandler${route}`} />
              </td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Language</td>
              <td><input type="text" name="language" id="language" value="EN" /></td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td colspan="2">Billing information(optional):</td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Billing Name</td>
              <td><input type="text" name="billing_name" value={data.name} /></td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td>Billing Email:</td>
              <td><input type="text" name="billing_email"
                value={data.email} /></td>
            </tr>
            <tr>
              <td>
                <center className='grid gap-1 sm:flex'>
                  <div><button onClick={() => { setActiveStep(4) }} className=" btn btn-primary">Back</button></div>
                  <div><p onClick={data.educationDetails ? saveEducationDetailsUpdate : saveEducationDetails} className=" btn btn-success">Save</p></div>
                  <div>{data.educationDetails ? <p className=" btn btn-primary" disabled={data.educationDetails.photo} onClick={toggleShow}>Preview</p> : <></>}</div>
                  <div style={{ textAlign: "center" }}>
                    {
                      data.educationDetails ? <button
                      disabled={checked == false || !data.educationDetails}
                      type="submit"
                      style={{ width: '24rem' }} className="btn btn-primary"
                    >
                      Final Submit & Proceed for Application Fee
                    </button>:
                    <div
                      onClick={()=>{return alert("First Click on 'Save' button to save your filled Details , then Proceed for Application fee Payment")}}
                      
                      style={{ width: '24rem' }} className="btn btn-primary"
                    >
                      Final Submit & Proceed for Application Fee
                    </div>
                    }
                  </div>
                </center>
              </td>
            </tr>
          </table>
        </form>
        {/* //////                 PAYMENT PAGE END                          */}
        <div className="col-12 flex justify-between flex-row-reverse  " style={{ marginTop: '5%' }}>
          {/* <button onClick={saveEducationDetails} className=" btn btn-primary">Save & Continue</button> */}
          {/* <button onClick={() => { navigate('/fillApplication-step4') }} className=" btn btn-primary">Back</button> */}
        </div>
      </div>
      {/* {
        preview ? <> */}
      <MDBModal show={optSmModal} tabIndex='-1' setShow={setOptSmModal}>
        <MDBModalDialog size='lg'>
          <MDBModalContent>
            <MDBModalHeader>
              {/* <MDBModalTitle className='text-black font-bold'>Preview Of Your Application</MDBModalTitle> */}
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>

            <MDBModalBody>
             
              {
                data.educationDetails && <>
                  {data.educationDetails.photo && 
                    <Preview program={route} />
                  }
                </>
              }
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      {/* </> :
          <>

          </>
      } */}
    </div>
  )
}


export default Step5



