import React, { useState, useEffect } from 'react'
import { MDBIcon } from 'mdb-react-ui-kit'
import { useNavigate } from 'react-router-dom'
import examination from './examination'

function Step4({ data, setActiveStep, title, route, getUpdate }) {
  const navigate = useNavigate()
  const [programIntrested, setProgramIntrested] = useState('MBA/PGDM')
  const [examinationMode, setExaminationMode] = useState('')
  const [examCity, setExamCity] = useState('')
  const [examState, setExamState] = useState('')
  const [scheduleExam, setScheduleExam] = useState('')
  const [branchPref, setBranchPref] = useState('')



  ///       Examination DETAILS
  async function saveExaminationDetails() {
    // event.preventDefault()
    if (examinationMode == 'Home Based Examination') {
      if (examinationMode && scheduleExam) {
        const response = await fetch('/home-based-examination', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            userId: data._id,
            examinationMode,
            scheduleExam: scheduleExam
          })
        })
          .then((response) => response.json())
          .then((data) => {
            // console.log(data)
            if (data.message == "changes successfully") {
              // alert('changes successfully')
              // setCentredModal(false)
              navigate('/fillApplication-step5')
            }
            else {
              alert('Error')
              // setCentredModal(false)
            }
          })
      }
      else {
        alert('Please filled All Details')
      }
    }
    else if (examinationMode == 'Centre Based Examination') {
      if (examinationMode && examCity && examState) {
        const response = await fetch('/centre-based-examination', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            userId: data._id,
            examinationMode,
            examCity: examCity,
            examState: examState
          })
        })
          .then((response) => response.json())
          .then((data) => {
            // console.log(data)
            if (data.message == "changes successfully") {
              // alert('changes successfully')
              // setCentredModal(false)
              navigate('/fillApplication-step5')
            }
            else {
              alert('Error')
              // setCentredModal(false)
            }
          })
      }
      else {
        alert('Please filled All Details')
      }
    }
    else {
      alert('Please filled All Details')
    }


  }

  // /application/engg/slot/:id
  //       Exam Slot  OF USER

  const [examSlot, setExamSlot] = useState('')


  async function examinationDetails(event) {
    event.preventDefault()

    await fetch(`/api/v2/application/${route}/slot/${data[route]._id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        examSlot,
        examinationMode,
        branchPref
      })
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert(data.message);
          setActiveStep(5)
          getUpdate()
        }
        else {
          alert(data.message)
        }

      })
  }
  const nextStep = () => {
    if (data.city) {
      setActiveStep(5)
    }
  }
  useEffect(() => {
    if (data[route].examSlot) {
      setExamSlot(data[route].examSlot)
      setExaminationMode(data[route].examinationMode)
      setBranchPref(data[route].branchPref)
    }
  }, [data])


  console.log("examination..........",examination)
  console.log("examination..........",route)
  return (
    <div className='paymentPage'>

      <h2 className='applicationCSS4 text-center pt-2 text-black font-bold' style={{ fontWeight: 'bolder' }}>{title}</h2>
      <div className='applicationCSS3'>
        <img className='fluid applicationCSS2' style={{ margin: 'auto' }} src="https://ucarecdn.com/3b937e9e-a545-4461-b4e7-e56572150261/step4.png" alt="" />
      </div>








      {/* ********************************   form                **************************** */}
      <div className='p-4 bg-white bootstrap-form text-black font-bold applicationCSS' >
        <form className="row g-3">
          <div className="col-md-6">
            <label for="inputState" className="form-label font-bold text-black">Level Of Examination</label><span className='text-red-600 text-xl font-bold'>*</span>
            <select disabled id="inputState" value={examination[route].lof} className=" cursor-not-allowed form-select text-black">
              <option value={examination[route].lof}>{examination[route].lof}</option>
            </select>
          </div>
          {
            route != "mba" &&

            <div className="col-md-6">
              <label for="inputState" className="form-label font-bold text-black">{examination[route].branchTitle} </label><span className='text-red-600 text-xl font-bold'>*</span>
              <select id="inputState" value={branchPref} onChange={(e) => { setBranchPref(e.target.value) }} className=" form-select text-black">
                <option selected>--Select---</option>
                {
                  examination[route].branchName.map((e) => {
                    return (
                      <>
                        <option key={e} value={e}>{e}</option>
                      </>
                    )
                  })
                }
                {/* <option value="">Engineering</option> */}
              </select>
            </div>
          }











          <div className="col-md-6">
            <label for="inputState" className="form-label font-bold text-black">Examination Mode</label><span className='text-red-600 text-xl font-bold'>*</span>
            <select id="inputState" value={examinationMode} onChange={(e) => { setExaminationMode(e.target.value) }} className="cursor-pointer form-select text-black">
              <option selected>Select Examination Mode</option>
              <option value="Home Based Examination">Home Based Examination</option>
              <option value="Centre Based Examination">Centre Based Examination</option>
            </select>
          </div>
          {
            examinationMode === "Home Based Examination" ?
              <>
                <div className="col-md-6">
                  <label for="inputState" className="form-label font-bold text-black">Schedule Your Examination</label><span className='text-red-600 text-xl font-bold'>*</span>
                  <select id="inputState" value={examSlot} onChange={(e) => { setExamSlot(e.target.value) }} className="cursor-pointer form-select text-black">
                    <option selected>--Schedule Your Examination--</option>
                    {
                      examination[route].exmaSlot.map((e) => {
                        return (
                          <option value={e}>{e}</option>
                        )
                      })
                    }
                  </select>
                </div>

              </>
              :
              <></>
          }
          {
            examinationMode === "Centre Based Examination" ?
              <>
                <span className='text-[14px] text-red-600'>Application is Full For Centre Based Examination Mode Please Select Home Based Examination Mode to Continue with AICEE-2024 Application</span>
                {/* <div className="col-md-6">
                  <label for="inputState" className="form-label font-bold text-black"> Your Examination Date</label><span className='text-red-600 text-xl font-bold'>*</span>
                  <select disabled id="inputState"   className="cursor-not-allowed form-select text-black">
                    <option selected>14th May, 2023</option>
                  </select>
                </div>
                <div className="col-md-6">
                  <label for="inputState" className="form-label font-bold text-black">Select Your Examination State</label><span className='text-red-600 text-xl font-bold'>*</span>
                  <select id="inputState" value={examState} onChange={(e) => { setExamState(e.target.value) }} className="cursor-pointer form-select text-black">
                    <option selected>--Select Your Examination State--</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Odisha">Odisha</option>
                    <option value="U.P">U.P</option>
                    <option value="North – East">North – East</option>
                    <option value="J & K">J & K</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="West Bengal">West Bengal</option>
                    <option value="Uttrakhand">Uttrakhand</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="M.P">M.P</option>
                    <option value="Chattisgarh">Chattisgarh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Goa">Goa</option>
                  </select>
                </div> */}
                {
                  examState === 'Bihar' ?
                    <>
                      <div className="col-md-6">
                        <label for="inputState" className="form-label font-bold text-black">Select Your Examination City</label><span className='text-red-600 text-xl font-bold'>*</span>
                        <select id="inputState" value={examCity} onChange={(e) => { setExamCity(e.target.value) }} className="cursor-pointer form-select text-black">
                          <option selected>--Select Your Examination City--</option>
                          <option value="Patna">Patna</option>
                          <option value="Muzaffarpur">Muzaffarpur</option>
                          <option value="Gaya">Gaya</option>
                          <option value="Darbhanga">Darbhanga</option>
                          <option value="Bhagalpur">Bhagalpur</option>
                          <option value="Samastipur">Samastipur</option>

                        </select>
                      </div>
                    </> : <></>
                }
                {
                  examState === 'Jharkhand' ?
                    <>
                      <div className="col-md-6">
                        <label for="inputState" className="form-label font-bold text-black">Select Your Examination City</label><span className='text-red-600 text-xl font-bold'>*</span>
                        <select id="inputState" value={examCity} onChange={(e) => { setExamCity(e.target.value) }} className="cursor-pointer form-select text-black">
                          <option selected>--Select Your Examination City--</option>
                          <option value="Ranchi">Ranchi</option>
                          <option value="Jamshedpur">Jamshedpur</option>
                          <option value="Dhanbad">Dhanbad</option>
                          <option value="Hazaribagh">Hazaribagh</option>
                          <option value="Bokaro">Bokaro</option>
                        </select>
                      </div>
                    </> : <></>
                }
                {
                  examState === 'Odisha' ?
                    <>
                      <div className="col-md-6">
                        <label for="inputState" className="form-label font-bold text-black">Select Your Examination City</label><span className='text-red-600 text-xl font-bold'>*</span>
                        <select id="inputState" value={examCity} onChange={(e) => { setExamCity(e.target.value) }} className="cursor-pointer form-select text-black">
                          <option selected>--Select Your Examination City--</option>
                          <option value="Bhubaneswar">Bhubaneswar</option>
                          <option value="Rourkela">Rourkela</option>
                        </select>
                      </div>
                    </> : <></>
                }
                {
                  examState === 'U.P' ?
                    <>
                      <div className="col-md-6">
                        <label for="inputState" className="form-label font-bold text-black">Select Your Examination City</label><span className='text-red-600 text-xl font-bold'>*</span>
                        <select id="inputState" value={examCity} onChange={(e) => { setExamCity(e.target.value) }} className="cursor-pointer form-select text-black">
                          <option selected>--Select Your Examination City--</option>
                          <option value="Varanasi">Varanasi</option>
                          <option value="Kanpur">Kanpur</option>
                          <option value="Lucknow">Lucknow</option>
                          <option value="Noida">Noida</option>
                          <option value="Ghaziabad">Ghaziabad</option>
                          <option value="Gr. Noida">Gr. Noida</option>
                        </select>
                      </div>
                    </> : <></>
                }
                {
                  examState === 'North – East' ?
                    <>
                      <div className="col-md-6">
                        <label for="inputState" className="form-label font-bold text-black">Select Your Examination City</label><span className='text-red-600 text-xl font-bold'>*</span>
                        <select id="inputState" value={examCity} onChange={(e) => { setExamCity(e.target.value) }} className="cursor-pointer form-select text-black">
                          <option selected>--Select Your Examination City--</option>
                          <option value="Guwahati">Guwahati</option>
                          <option value="Gangtok">Gangtok</option>
                          <option value="Itanagar">Itanagar</option>
                        </select>
                      </div>
                    </> : <></>
                }
                {
                  examState === 'J & K' ?
                    <>
                      <div className="col-md-6">
                        <label for="inputState" className="form-label font-bold text-black">Select Your Examination City</label><span className='text-red-600 text-xl font-bold'>*</span>
                        <select id="inputState" value={examCity} onChange={(e) => { setExamCity(e.target.value) }} className="cursor-pointer form-select text-black">
                          <option selected>--Select Your Examination City--</option>
                          <option value="Jammu">Jammu</option>
                        </select>
                      </div>
                    </> : <></>
                }
                {
                  examState === 'Gujarat' ?
                    <>
                      <div className="col-md-6">
                        <label for="inputState" className="form-label font-bold text-black">Select Your Examination City</label><span className='text-red-600 text-xl font-bold'>*</span>
                        <select id="inputState" value={examCity} onChange={(e) => { setExamCity(e.target.value) }} className="cursor-pointer form-select text-black">
                          <option selected>--Select Your Examination City--</option>
                          <option value="Ahmedabad">Ahmedabad</option>
                          <option value="Surat">Surat</option>
                          <option value="Rajkot">Rajkot</option>
                          <option value="Vadodara">Vadodara</option>
                        </select>
                      </div>
                    </> : <></>
                }
                {
                  examState === 'West Bengal' ?
                    <>
                      <div className="col-md-6">
                        <label for="inputState" className="form-label font-bold text-black">Select Your Examination City</label><span className='text-red-600 text-xl font-bold'>*</span>
                        <select id="inputState" value={examCity} onChange={(e) => { setExamCity(e.target.value) }} className="cursor-pointer form-select text-black">
                          <option selected>--Select Your Examination City--</option>
                          <option value="Kolkata">Kolkata</option>
                          <option value="Siliguri">Siliguri</option>
                          <option value="Darjeeling">Darjeeling</option>
                        </select>
                      </div>
                    </> : <></>
                }
                {
                  examState === 'Uttrakhand' ?
                    <>
                      <div className="col-md-6">
                        <label for="inputState" className="form-label font-bold text-black">Select Your Examination City</label><span className='text-red-600 text-xl font-bold'>*</span>
                        <select id="inputState" value={examCity} onChange={(e) => { setExamCity(e.target.value) }} className="cursor-pointer form-select text-black">
                          <option selected>--Select Your Examination City--</option>
                          <option value="Dehradun">Dehradun</option>
                        </select>
                      </div>
                    </> : <></>
                }
                {
                  examState === 'Punjab' ?
                    <>
                      <div className="col-md-6">
                        <label for="inputState" className="form-label font-bold text-black">Select Your Examination City</label><span className='text-red-600 text-xl font-bold'>*</span>
                        <select id="inputState" value={examCity} onChange={(e) => { setExamCity(e.target.value) }} className="cursor-pointer form-select text-black">
                          <option selected>--Select Your Examination City--</option>
                          <option value="Chandigarh">Chandigarh</option>
                          <option value="Amritsar">Amritsar</option>
                        </select>
                      </div>
                    </> : <></>
                }
                {
                  examState === 'Haryana' ?
                    <>
                      <div className="col-md-6">
                        <label for="inputState" className="form-label font-bold text-black">Select Your Examination City</label><span className='text-red-600 text-xl font-bold'>*</span>
                        <select id="inputState" value={examCity} onChange={(e) => { setExamCity(e.target.value) }} className="cursor-pointer form-select text-black">
                          <option selected>--Select Your Examination City--</option>
                          <option value="Gurugram">Gurugram</option>
                          <option value="Faridabad">Faridabad</option>
                        </select>
                      </div>
                    </> : <></>
                }
                {
                  examState === 'Delhi' ?
                    <>
                      <div className="col-md-6">
                        <label for="inputState" className="form-label font-bold text-black">Select Your Examination City</label><span className='text-red-600 text-xl font-bold'>*</span>
                        <select id="inputState" value={examCity} onChange={(e) => { setExamCity(e.target.value) }} className="cursor-pointer form-select text-black">
                          <option selected>--Select Your Examination City--</option>
                          <option value="Delhi">Delhi</option>
                        </select>
                      </div>
                    </> : <></>
                }
                {
                  examState === 'Rajasthan' ?
                    <>
                      <div className="col-md-6">
                        <label for="inputState" className="form-label font-bold text-black">Select Your Examination City</label><span className='text-red-600 text-xl font-bold'>*</span>
                        <select id="inputState" value={examCity} onChange={(e) => { setExamCity(e.target.value) }} className="cursor-pointer form-select text-black">
                          <option selected>--Select Your Examination City--</option>
                          <option value="Udaipur">Udaipur</option>
                          <option value="Jaipur">Jaipur</option>
                          <option value="Jodhpur">Jodhpur</option>
                        </select>
                      </div>
                    </> : <></>
                }
                {
                  examState === 'M.P' ?
                    <>
                      <div className="col-md-6">
                        <label for="inputState" className="form-label font-bold text-black">Select Your Examination City</label><span className='text-red-600 text-xl font-bold'>*</span>
                        <select id="inputState" value={examCity} onChange={(e) => { setExamCity(e.target.value) }} className="cursor-pointer form-select text-black">
                          <option selected>--Select Your Examination City--</option>
                          <option value="Bhopal">Bhopal</option>
                          <option value="Indore">Indore</option>
                        </select>
                      </div>
                    </> : <></>
                }
                {
                  examState === 'Chattisgarh' ?
                    <>
                      <div className="col-md-6">
                        <label for="inputState" className="form-label font-bold text-black">Select Your Examination City</label><span className='text-red-600 text-xl font-bold'>*</span>
                        <select id="inputState" value={examCity} onChange={(e) => { setExamCity(e.target.value) }} className="cursor-pointer form-select text-black">
                          <option selected>--Select Your Examination City--</option>
                          <option value="Raipur">Raipur</option>
                        </select>
                      </div>
                    </> : <></>
                }
                {
                  examState === 'Maharashtra' ?
                    <>
                      <div className="col-md-6">
                        <label for="inputState" className="form-label font-bold text-black">Select Your Examination City</label><span className='text-red-600 text-xl font-bold'>*</span>
                        <select id="inputState" value={examCity} onChange={(e) => { setExamCity(e.target.value) }} className="cursor-pointer form-select text-black">
                          <option selected>--Select Your Examination City--</option>
                          <option value="Pune">Pune</option>
                          <option value="Nagpur">Nagpur</option>
                        </select>
                      </div>
                    </> : <></>
                }
                {
                  examState === 'Karnataka' ?
                    <>
                      <div className="col-md-6">
                        <label for="inputState" className="form-label font-bold text-black">Select Your Examination City</label><span className='text-red-600 text-xl font-bold'>*</span>
                        <select id="inputState" value={examCity} onChange={(e) => { setExamCity(e.target.value) }} className="cursor-pointer form-select text-black">
                          <option selected>--Select Your Examination City--</option>
                          <option value="Bengaluru">Bengaluru</option>
                        </select>
                      </div>
                    </> : <></>
                }
                {
                  examState === 'Andhra Pradesh' ?
                    <>
                      <div className="col-md-6">
                        <label for="inputState" className="form-label font-bold text-black">Select Your Examination City</label><span className='text-red-600 text-xl font-bold'>*</span>
                        <select id="inputState" value={examCity} onChange={(e) => { setExamCity(e.target.value) }} className="cursor-pointer form-select text-black">
                          <option selected>--Select Your Examination City--</option>
                          <option value="Amravati">Amravati</option>
                        </select>
                      </div>
                    </> : <></>
                }
                {
                  examState === 'Tamil Nadu' ?
                    <>
                      <div className="col-md-6">
                        <label for="inputState" className="form-label font-bold text-black">Select Your Examination City</label><span className='text-red-600 text-xl font-bold'>*</span>
                        <select id="inputState" value={examCity} onChange={(e) => { setExamCity(e.target.value) }} className="cursor-pointer form-select text-black">
                          <option selected>--Select Your Examination City--</option>
                          <option value="Chennai">Chennai</option>
                        </select>
                      </div>
                    </> : <></>
                }
                {
                  examState === 'Telangana' ?
                    <>
                      <div className="col-md-6">
                        <label for="inputState" className="form-label font-bold text-black">Select Your Examination City</label><span className='text-red-600 text-xl font-bold'>*</span>
                        <select id="inputState" value={examCity} onChange={(e) => { setExamCity(e.target.value) }} className="cursor-pointer form-select text-black">
                          <option selected>--Select Your Examination City--</option>
                          <option value="Hyderabad">Hyderabad</option>
                        </select>
                      </div>
                    </> : <></>
                }
                {
                  examState === 'Kerala' ?
                    <>
                      <div className="col-md-6">
                        <label for="inputState" className="form-label font-bold text-black">Select Your Examination City</label><span className='text-red-600 text-xl font-bold'>*</span>
                        <select id="inputState" value={examCity} onChange={(e) => { setExamCity(e.target.value) }} className="cursor-pointer form-select text-black">
                          <option selected>--Select Your Examination City--</option>
                          <option value="Kochi">Kochi</option>
                        </select>
                      </div>
                    </> : <></>
                }
                {
                  examState === 'Goa' ?
                    <>
                      <div className="col-md-6">
                        <label for="inputState" className="form-label font-bold text-black">Select Your Examination City</label><span className='text-red-600 text-xl font-bold'>*</span>
                        <select id="inputState" value={examCity} onChange={(e) => { setExamCity(e.target.value) }} className="cursor-pointer form-select text-black">
                          <option selected>--Select Your Examination City--</option>
                          <option value="Panaji">Panaji</option>
                        </select>
                      </div>
                    </> : <></>
                }
              </>
              :
              <></>
          }
        </form>
        <div className="col-12 flex justify-between flex-row-reverse" style={{ marginTop: '10%' }}>
          <button onClick={examinationDetails} className="btn btn-primary">Save & Continue</button>
          <button onClick={() => { setActiveStep(3) }} className="btn btn-primary">Back</button>
        </div>
      </div>
    </div>
  )
}

export default Step4



