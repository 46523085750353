import React, { useState } from 'react'
import Countdown from "react-countdown";

import {
    MDBCard, MDBContainer,
    MDBCol,
    MDBRow,
    MDBBtn,
    MDBIcon,
    MDBInput,
    MDBCheckbox,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalFooter,
    MDBCardHeader,
    MDBCardBody,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody
} from 'mdb-react-ui-kit';
import ApplicationStep from './ApplicationStep';
import ApplicationStages from './ApplicationStages';
// import ApplicationImage from './ApplicationImage';
import './Registration.css'
// import aicee2023logo from '../../Images/aicee2023logo.png'
import aicee2023logo from '../../Pages/LandingPage/img/logo1.png'
// import step from '../../Images/step.png'
import banner from '../../Images/banner.webp'
import RegistrationVideo from './RegistrationVideo';
import newgif from '../../Images/new.gif'
import Footer from './Footer';
// import ReCAPTCHA from "react-google-recaptcha";
import Header from '../../Pages/Registration/Header'
// const recaptchaRef = React.createRef();
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom'


function Registration() {
    const userIds = useParams();
    console.log(userIds.id)
    const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [timer, setTimer] = useState('00:00:00');
    const [userId, setUserId] = useState()
    const [otp, setOtp] = useState()
    const [sendMob, setSendMob] = useState("")
    const [open, setOpen] = React.useState(false);
    const [video, setVideo] = useState('https://counselling.aicee.in/api/v1/download/AICEE%20NEW%201%20Rajesh%20.mp4')

    const [justifyActive, setJustifyActive] = useState('tab2');
    const navigate = useNavigate()
    // console.log(justifyActive)
    const handleJustifyClick = (value) => {
        if (value === justifyActive) {
            return;
        }

        setJustifyActive(value);
    };

    const [studentName, setStudentName] = useState('')
    const [country, setCountry] = useState('')
    const [gender, setGender] = useState('')
    const [mobileNo, setMobileNo] = useState()
    const [emailId, setEmailId] = useState('')
    const [datareg, setDatareg] = useState({})
    const [enterEmailOtp, setEnterEmailOtp] = useState()
    const [enterMobileOtp, setEnterMobileOtp] = useState()
    const [studentUserId, setStudentUserId] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [emailVerifiedSuccess, setEmailVerifiedSuccess] = useState(false)
    const [mobileVerifiedSuccess, setMobileVerifiedSuccess] = useState(false)
    const [program, setProgram] = useState("false")
    // console.log(datareg.message)
    // console.log(studentName, country, gender, mobileNo, emailId)
    async function registrationUser(event) {
        event.preventDefault()
        setOpen(true)
        await fetch('/api/v2/user/registration', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: studentName,
                country,
                gender,
                mobileNo,
                email: emailId,
                program
            })
        })
            .then((response) => response.json())
            .then((data) => {
                setOpen(false)
                if (data.success) {
                    // setOpen(false)
                    setFullscreenXlModal(true)
                    setDatareg(data)
                    setStudentUserId(data.data.userId)
                    senMobileOtp()
                }
                else {
                    alert(data.message)

                    // console.log(data)
                }
            })
            .catch((err) => {
                alert(err.response.message)
            })


    }
    async function verifyEmailOtp(event) {
        event.preventDefault()
        setOpen(true)
        const response = await fetch('/api/v2/user/verifyOTP', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId: studentUserId,
                otp: enterEmailOtp
            })
        })
        const data = await response.json()
        if (data.message == 'Invalid code pass . check your inbox') {
            alert('Invalid Otp')
            setShowAlert(true)
            setOpen(false)
        }
        else if (data.message == 'Empty otp detailsare not allowed') {
            alert('Please Enter Email Otp')
            setShowAlert(true)
            setOpen(false)
        }
        else if (data.message == 'code has expired .  Please request again') {
            alert('Expired Email Otp')
            setShowAlert(true)
            setOpen(false)
        }
        else {
            setEmailVerifiedSuccess(true)
            setOpen(false)
        }


        // console.log(data.message)

    }
    async function verifiedMobile(event) {
        setOpen(true)
        event.preventDefault()
        if (sendMob == enterMobileOtp) {
            const response = await fetch('/mobile-otp-verifyOTP', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userId: studentUserId

                })
            })
            const data = await response.json()
            if (data.message == 'Empty otp detailsare not allowed') {
                alert('Please Enter otp')
                setShowAlert(true)
                setOpen(false)
            }
            else {
                setMobileVerifiedSuccess(true)
                setOpen(false)
            }


            // console.log(data.message)
        }
        else {
            alert('Invalid Otp')
            setShowAlert(true)
            setOpen(false)
        }

    }
    async function resendEmailOtp(event) {
        setOpen(true)
        event.preventDefault()
        const response = await fetch('/resendOTPVerification', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                studentName,
                userId,
                emailId
            })
        })
        const data = await response.json()
        if (data.message === 'verification otp email sent') {
            alert('otp has been sent successfully')
            setOpen(false)
        }
        else {
            alert('Error')
            setOpen(false)
        }
        console.log(data)
    }

    //           FINAL SUBMIT OF REGISTRATION FORM 
    const [showModal, setShowModal] = React.useState(false);
    async function passwordSave(event) {
        setOpen(true)
        event.preventDefault()
        const password = Math.floor(1000 + Math.random() * 900000)
        const response = await fetch('/create-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                studentName,
                userId: studentUserId,
                password,
                emailId
            })
        })
        const data = await response.json()
        if (data.success) {
            setFullscreenXlModal(false)
            setJustifyActive("tab1")
            sendLoginCredentails(password)
           
            setOpen(false)
            setShowModal(true)
            setStudentName("")
            setGender('')
            setCountry('')
            setMobileNo('')
            setEmailId('')
            sendLoginCredentails()
        }
        else {
            alert(data.message)
            setOpen(false)
        }
        

    }


    ///     SEND MOBILE OTP LOGIN CREDENTALS

    async function sendLoginCredentails() {

        try {
            // const mobileOtp = Math.floor(1000 + Math.random() * 900000)
            // setSendMob(mobileOtp)
            const res = await fetch(
                //     `
                // http://bulksms.sakshisoftware.in/api/mt/SendSMS?user=Rajnish&password=242726&senderid=AICEEE&channel=trans&DCS=0&flashsms=0&number=${mobileNo}&text=Dear ${studentName} Your User ID is Your Registered email id and your Password is ${password} . Kindly login using these credentials . - Team AICEE

                // `
                `
                https://www.smppsmshub.in/api/mt/SendSMS?user=rajnishh&password=Rajn@123&senderid=AICEEE&channel=trans&DCS=0&flashsms=0&number=${mobileNo}&text=Dear ${studentName} Your User ID is Your Registered email id and your Password is ${enterEmailOtp} . Kindly login using these credentials . - Team AICEE&route=15&DLTTemplateId=1207164362823022213&PEID=1201161787453040178
                
                `
                


            )
            
            // setOpen(false)
            // 


        } catch (error) {

        }
    }
    ///     SEND MOBILE OTP 

    async function senMobileOtp() {

        try {
            const mobileOtp = Math.floor(1000 + Math.random() * 900000)
            setSendMob(mobileOtp)
            const res = await fetch(
                // `http://bulksms.sakshisoftware.in/api/mt/SendSMS?user=Rajnish&password=242726&senderid=AICEEE&channel=trans&DCS=0&flashsms=0&number=${mobileNo}&text=Dear Applicant ${mobileOtp} is the OTP to Confirm your AICEE Application - Team AICEE&route=4`
                `https://www.smppsmshub.in/api/mt/SendSMS?user=rajnishh&password=Rajn@123&senderid=AICEEE&channel=trans&DCS=0&flashsms=0&number=${mobileNo}&text=Dear Applicant ${mobileOtp} is the OTP to Confirm your AICEE Application - Team AICEE&route=15&DLTTemplateId=1207164362712744264&PEID=1201161787453040178`
            )
            console.log(res)
            // alert('Otp has been sent sucessfully')

        } catch (error) {

        }
    }

    // console.log(sendMob)


    // **********************************     LOADER      ***********************************  //

    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };
    // ********************************       END LOADER     ************************************ //

    // checked or not
    const [checked, setChecked] = useState(false)
    const handleChange = () => {
        setChecked(!checked)
    }


    /// login start
    const [loginEmail, setLoginEmail] = useState('')
    const [loginPassword, setLoginPassword] = useState('')
    async function loginUser(event) {
        event.preventDefault()
        if (!loginEmail || !loginPassword) {
            alert('invalid credentials')
        }
        else {
            const response = await fetch('/online-student/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    emailId: loginEmail,
                    password: loginPassword
                })
            })
            const data = await response.json()
            
            if (data.success) {
                navigate('/student-dashboard')
                
            }
            else {
                alert(data.message)
            }
            //   console.log(data)
        }
    }
    // login end

    //   FORGET PASSWORD
    const [centredModal, setCentredModal] = useState(false);
    const toggleShow = () => setCentredModal(!centredModal);
    const [forgotPassword, setForgotPassword] = useState('')
    async function forgotPasswordOfStudent(event) {
        event.preventDefault()
        setOpen(true)
        if (!forgotPassword) {
            alert('invalid credentials')
            setOpen(false)
        }
        else {
            const response = await fetch('/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    emailId: forgotPassword

                })
            })
            const data = await response.json()
            console.log(data)
            if (data.success) {
                setCentredModal(false)
                setForgotPassword('')
                alert(data.message)
                setOpen(false)
            }

            else {
                setForgotPassword('')
                alert('Please Enter Registered Email Id')
                setOpen(false)
            }

            //   console.log(data)
        }

    }






    // <p>Please Wait ..........</p>
    // <script>
    //  var timer = setTimeout(function() {
    //      window.location='https://application-patyment-fee-successful'
    //  }, 3000);
    // </script>

    return (
        <div className='RegistrationCardContainer'>
            <div className='RegistrationCard'>
                <div className='RegistrationCard2'>
                    <MDBCard >
                        <div className='RegistrationCard3'>
                            <center className='RegistrationCard4'><img className='fluid' src={aicee2023logo} alt="aicee" /></center>

                            <div className='RegistrationCard5'><p>ALL India Combined Entrance Examination</p>
                                <div className='mt-1'>
                                    <marquee style={{ marginTop: '-15px', color: 'white', color: 'black', fontWeight: 'bolder', textTransform: 'capitalize', fontWeight: '500', fontFamily: 'Open Sans, sans-serif' }} behavior="" direction="" ><span style={{ display: 'flex' }}><span>AICEE 2024 Application Open Now</span> <sup><img src={newgif} alt="gif" /></sup></span></marquee>
                                </div>
                            </div>


                        </div>
                        <hr />

                        <MDBContainer fluid className="p-4 my-5">

                            <MDBRow style={{ marginTop: '-5rem' }}>

                                <MDBCol style={{ border: '1px solid #BDBDBD', borderRadius: '5px' }} col='6' md='5'>
                                    <MDBTabs style={{ backgroundColor: '#04124f', width: '100%', padding: '0px' }} className='mb-3 d-flex  '>
                                        {
                                            justifyActive == "tab2" ?
                                                <>
                                                    <MDBTabsItem style={{ listStyle: 'none' }}>
                                                        <MDBTabsLink style={{ fontSize: '0.9rem', fontWeight: 'bold', border: 'none', backgroundColor: 'white', color: '#17a2b8' }} onClick={() => handleJustifyClick('tab2')} active={justifyActive === 'tab2'}>
                                                            New User
                                                        </MDBTabsLink>
                                                    </MDBTabsItem>
                                                    <MDBTabsItem style={{ listStyle: 'none' }}>
                                                        <MDBTabsLink style={{ fontSize: '0.9rem', fontWeight: 'bold', border: 'none', color: 'white' }} onClick={() => handleJustifyClick('tab1')} active={justifyActive === 'tab1'}>
                                                        Registered User Login
                                                        </MDBTabsLink>
                                                    </MDBTabsItem>
                                                </> :
                                                <>
                                                    <MDBTabsItem style={{ listStyle: 'none' }}>
                                                        <MDBTabsLink style={{ fontSize: '0.9rem', fontWeight: 'bold', border: 'none', color: 'white' }} onClick={() => handleJustifyClick('tab2')} active={justifyActive === 'tab2'}>
                                                            New User
                                                        </MDBTabsLink>
                                                    </MDBTabsItem>
                                                    <MDBTabsItem style={{ listStyle: 'none' }}>
                                                        <MDBTabsLink style={{ fontSize: '0.9rem', fontWeight: 'bold', border: 'none', backgroundColor: 'white', color: '#17a2b8' }} onClick={() => handleJustifyClick('tab1')} active={justifyActive === 'tab1'}>
                                                            Login
                                                        </MDBTabsLink>
                                                    </MDBTabsItem>
                                                </>
                                        }
                                    </MDBTabs>
                                    <MDBTabsContent >

                                        <MDBTabsPane show={justifyActive === 'tab1'}>



                                            <label htmlFor="register" style={{ textAlgn: 'start', fontWeight: 'bold', fontSize: '1rem', color: 'black', fontFamily: 'Open Sans, sans-serif' }}> <MDBIcon fas icon="envelope" /> Email ID<span style={{ color: "#f90000", fontWeight: 'bolder' }}>*</span>
                                            </label>
                                            <MDBInput onChange={(e) => { setLoginEmail(e.target.value) }} autoComplete="off" style={{ fontSize: '' }} wrapperClass='mb-2' id='form1' type='text' />
                                            <label htmlFor="register" style={{ textAlgn: 'start', fontWeight: 'bold', fontSize: '1rem', color: 'black', fontFamily: 'Open Sans, sans-serif' }}> <MDBIcon fas icon="key" /> Password<span style={{ color: "#f90000", fontWeight: 'bolder' }}>*</span>
                                            </label>
                                            <MDBInput onChange={(e) => { setLoginPassword(e.target.value) }} autoComplete="off" style={{ fontSize: '' }} wrapperClass='mb-2' id='form1' type='password' />

                                            <div style={{ textAlign: 'right' }}>

                                                <span className='cursor-pointer' onClick={toggleShow}>Forgot password?</span>
                                            </div>

                                            <MDBBtn style={{ backgroundColor: '#04124f' }} onClick={loginUser} className="mb-4 w-100">Sign in</MDBBtn>


                                        </MDBTabsPane>

                                        <MDBTabsPane show={justifyActive === 'tab2'}>


                                            <label htmlFor="register" style={{ textAlgn: 'start', fontWeight: '700', fontSize: 'inherit', color: 'black', fontFamily: 'Open Sans, sans-serif' }}> <MDBIcon fas icon="user" /> Candidate Name<span style={{ color: "#f90000", fontWeight: 'bolder' }}>*</span>
                                            </label>
                                            <MDBInput value={studentName} onChange={(e) => setStudentName(e.target.value)} autoComplete="off" style={{ fontSize: '' }} wrapperClass='mb-2' type='text' placeholder='Your Full Name as Per Your Marksheet' />
                                            <label htmlFor="register" style={{ textAlgn: 'start', fontWeight: '700', color: 'black', fontFamily: 'Open Sans, sans-serif', fontSize: 'inherit' }}> <MDBIcon fas icon="globe-africa" /> Country<span style={{ color: "#f90000", fontWeight: 'bolder' }}>*</span>
                                            </label>

                                            <select onChange={(e) => setCountry(e.target.value)} style={{ width: '100%', border: 'none', border: '1px solid #BDBDBD', borderRadius: '5px', color: '#676767', padding: '0.4rem', marginBottom: '0.8rem' }} name="" id="">
                                                <option style={{ border: 'none' }} value="">Select Country</option>
                                                <option style={{ border: 'none' }} value="India">India</option>
                                                <option style={{ border: 'none' }} value="Nepal">Nepal</option>
                                            </select>
                                            <label htmlFor="register" style={{ textAlgn: 'start', fontWeight: 'bold', fontSize: '1rem', color: 'black', fontFamily: 'Open Sans, sans-serif' }}> <MDBIcon fas icon="bullseye" /> Gender<span style={{ color: "#f90000", fontWeight: 'bolder' }}>*</span>
                                            </label>
                                            <select onChange={(e) => setGender(e.target.value)} style={{ width: '100%', border: 'none', border: '1px solid #BDBDBD', borderRadius: '5px', color: '#676767', padding: '0.4rem', marginBottom: '0.8rem' }} name="" id="">
                                                <option style={{ border: 'none' }} value="">Select Gender</option>
                                                <option style={{ border: 'none' }} value="Male">Male</option>
                                                <option style={{ border: 'none' }} value="Female">Female</option>
                                                <option style={{ border: 'none' }} value="Transgender">Transgender</option>
                                            </select>
                                            <label htmlFor="register" style={{ textAlgn: 'start', fontWeight: 'bold', fontSize: '1rem', color: 'black', fontFamily: 'Open Sans, sans-serif' }}> <MDBIcon fas icon="tasks" /> Proramme<span style={{ color: "#f90000", fontWeight: 'bolder' }}>*</span>
                                            </label>
                                            <select value={program} onChange={(e) => setProgram(e.target.value)} style={{ width: '100%', border: 'none', border: '1px solid #BDBDBD', borderRadius: '5px', color: '#676767', padding: '0.4rem', marginBottom: '0.8rem' }} name="" id="">
                                                <option style={{ border: 'none' }} value="">Select Programme</option>
                                                <option style={{ border: 'none' }} value="MBA / PGDM">MBA / PGDM</option>
                                                <option style={{ border: 'none' }} value="Engineering ( B-Tech / B.E )">Engineering ( B-Tech / B.E )</option>
                                                <option style={{ border: 'none' }} value="Bio Technology ( Bsc. BioTechnology / B-Tech BioTechnology )">Bio Technology ( Bsc. BioTechnology / B-Tech BioTechnology )</option>
                                                <option style={{ border: 'none' }} value="B.Pharmacy">B.Pharmacy</option>
                                                <option style={{ border: 'none' }} value="Agriculture ( Bsc. Agriculture / B-Tech Agriculture )">Agriculture ( Bsc. Agriculture / B-Tech Agriculture )</option>
                                                <option style={{ border: 'none' }} value="Bsc Datascience">Bsc Datascience</option>
                                                <option style={{ border: 'none' }} value="B.Pharmacy">B.Pharmacy</option>
                                                <option style={{ border: 'none' }} value="Bsc.Nursing">Bsc.Nursing</option>
                                                <option style={{ border: 'none' }} value="Bsc.Agriculture">Bsc.Agriculture</option>
                                                <option style={{ border: 'none' }} value="Bio-Tech ( Bsc.BioTech / B-Tech.BioTech)">Bio-Tech ( Bsc.BioTech / B-Tech.BioTech)</option>
                                                <option style={{ border: 'none' }} value="BBA">BBA</option>
                                                <option style={{ border: 'none' }} value="BCA">BCA</option>
                                                <option style={{ border: 'none' }} value="B.Design">B.Design</option>
                                                <option style={{ border: 'none' }} value="LLB (BA / B.Com / B.sc / BBA / BCA)">LLB (BA / B.Com / B.sc / BBA / BCA)</option>
                                                <option style={{ border: 'none' }} value="Mass Communication & Journalism">Mass Communication & Journalism</option>

                                            </select>

                                            <label htmlFor="register" style={{ textAlgn: 'start', fontWeight: 'bold', fontSize: '1rem', color: 'black', fontFamily: 'Open Sans, sans-serif' }}> <MDBIcon fas icon="phone-alt" /> Mobile No<span style={{ color: "#f90000", fontWeight: 'bolder' }}>*</span><span className='text-[0.8rem]'>(without adding +91 or 0)</span>
                                            </label>

                                            <MDBInput value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} autoComplete="off" style={{ fontSize: '' }} wrapperClass='mb-2' id='form1' type='phone' />
                                            <label htmlFor="register" style={{ textAlgn: 'start', fontWeight: 'bold', fontSize: '1rem', color: 'black', fontFamily: 'Open Sans, sans-serif' }}> <MDBIcon fas icon="envelope" /> Email ID<span style={{ color: "#f90000", fontWeight: 'bolder' }}>*</span>
                                            </label>
                                            <MDBInput value={emailId} onChange={(e) => setEmailId(e.target.value)} autoComplete="off" style={{ fontSize: '' }} wrapperClass='mb-2' id='form1' type='text' />

                                            <div className='d-flex justify-content-center mb-2'>
                                                <MDBCheckbox value={checked} onChange={handleChange} name='flexCheck' id='flexCheckDefault' label='' /><span style={{ fontSize: '0.8rem', fontWeight: 'bolder', color: 'black' }}> I authorize AICEE to use my mobile number / Email Id/ connected social media platform overriding DND status.</span>
                                            </div>

                                            <MDBBtn disabled={checked == false} onClick={registrationUser} style={{ backgroundColor: '#04124f', fontFamily: 'Open Sans, sans-serif' }} className="mb-4 w-100">Register</MDBBtn>

                                        </MDBTabsPane>

                                    </MDBTabsContent>

                                </MDBCol>

                                <MDBCol col='6' md='7' className=''>

                                    <img style={{ width: '150%', height: '80%', borderRadius: '15px' }} src={banner} className="img-fluid" alt="Phone image" />



                                </MDBCol>

                            </MDBRow>

                        </MDBContainer>
                        <div className='registrationStep'>
                            <ApplicationStep />
                        </div>
                        {/* <img style={{ width: '100%', marginTop: '-5rem' }} src={step} alt="" /> */}
                    </MDBCard>



                </div>
                <div className='mt-4 applicationStages'>
                    <div><RegistrationVideo videos={video} /></div>
                    <div style={{ marginTop: '-0.3rem' }}><ApplicationStages /></div>
                </div>
                <div className='grid grid-rows-2 m-auto text-center  mt-4 mb-4  grid-flow-col gap-5 sm:grid-rows-1 pl-4 pr-4'>
                    <div onClick={() => { setVideo('https://counselling.aicee.in/api/v1/download/AICEE%20NEW%201.mp4') }} className='p-2 text-white rounded-lg  cursor-pointer ' style={{ backgroundColor: '#04124f' }}><MDBIcon fab icon="youtube" /> AICEE Video 1</div>
                    <div onClick={() => { setVideo('https://counselling.aicee.in/api/v1/download/AICEE%20NEW%202.mp4') }} className='p-2 text-white rounded-lg  cursor-pointer ' style={{ backgroundColor: '#04124f' }}><MDBIcon fab icon="youtube" /> AICEE Video 2</div>
                    <div onClick={() => { setVideo('https://counselling.aicee.in/api/v1/download/AICEE%20NEW%203.mp4') }} className='p-2 text-white rounded-lg cursor-pointer ' style={{ backgroundColor: '#04124f' }}> <MDBIcon fab icon="youtube" /> AICEE Video 3</div>
                    <div onClick={() => { setVideo('https://counselling.aicee.in/api/v1/download/AICEE%20NEW%204.mp4') }} className='p-2 text-white rounded-lg cursor-pointer ' style={{ backgroundColor: '#04124f' }}> <MDBIcon fab icon="youtube" /> AICEE Video 4</div>
                    {/* <div className='p-2 text-white rounded-lg cursor-pointer ' style={{ backgroundColor: '#04124f' }}> <MDBIcon fab icon="youtube" /> AICEE Video 5</div> */}
                </div>
            </div>


       





            <div><Footer /></div>
            {/* ***********************************   OTP Verification Page     **************************** */}
            <MDBModal tabIndex='-1' show={fullscreenXlModal} setShow={setFullscreenXlModal}>
                <MDBModalDialog size='fullscreen-xxl-down'>
                    <MDBModalContent>

                        <Header />
                        <MDBCard className='otpCard'>
                            <MDBCardHeader className='text-center text-white font-bold' style={{ backgroundColor: '#04124f' }}>OTP Verification</MDBCardHeader>
                            <MDBCardBody>
                                <div className="mb-3">
                                    <label htmlFor="formGroupExampleInput" className=" form-label font-bold text-black">Enter Mobile OTP</label>
                                    <div className="flex justify-around gap-5">
                                        <input type="text" onChange={(e) => { setEnterMobileOtp(e.target.value) }} className="shadow-none outline-none border text-black w-[100px] sm:w-[200px]" id="formGroupExampleInput" placeholder="" />
                                        <div className='flex justify-around gap-3'>
                                            {
                                                mobileVerifiedSuccess == false ?
                                                    <>
                                                        <MDBBtn onClick={verifiedMobile}>Verify OTP</MDBBtn>
                                                        {/* <Countdown date={Date.now() + 120000}> */}
                                                        {/* <MDBBtn onClick={senMobileOtp}>Resend OTP</MDBBtn> */}
                                                        {/* </Countdown> */}

                                                    </> :
                                                    <>
                                                        <p className='text-green font-bold text-md text-green-700'>Veified</p>
                                                    </>
                                            }
                                        </div>

                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="formGroupExampleInput2" class="form-label font-bold text-black">Enter Email OTP</label>
                                    <div className="flex justify-around gap-5">
                                        <input type="text" className="shadow-none outline-none border text-black w-[100px] sm:w-[200px]" id="formGroupExampleInput" onChange={(e) => { setEnterEmailOtp(e.target.value) }} placeholder="" />
                                        <div className='flex justify-around gap-3'>
                                            {
                                                emailVerifiedSuccess == false ?
                                                    <>
                                                        <MDBBtn onClick={verifyEmailOtp}>Verify OTP</MDBBtn>
                                                        {/* <Countdown date={Date.now() + 120000}> */}
                                                        {/* <MDBBtn onClick={resendEmailOtp}>Resend OTP</MDBBtn> */}
                                                        {/* </Countdown>, */}

                                                    </> :
                                                    <>
                                                        <p className='text-green font-bold text-md text-green-700'>Veified</p>
                                                    </>
                                            }

                                        </div>
                                    </div>
                                    <div className='mt-5 '>
                                        <span className='text-red-500 font-bold ' style={{ marginTop: '5rem' }}> <li className='text-justify'>Enter OTP & Click on Verify button to verify your mobile & Email OTP .</li></span>
                                        <span className='text-red-500 font-bold ' style={{ marginTop: '5rem' }}> <li className='text-justify'>Please Wait for 60 Second to get the mobile OTP.</li></span>
                                        <span className='text-red-500 font-bold' style={{ marginTop: '5rem' }}> <li>Always Check Your Spam box or Promotional messages if You are not getting the OTP in your Inbox mail. Also Tick on 'not as spam ' to get all your your future E-mails in your Inbox.</li>
                                        
                                        </span>
                                        
                                    </div>
                                </div>
                                <center><MDBBtn disabled={!emailVerifiedSuccess || !mobileVerifiedSuccess} onClick={passwordSave}>Submit</MDBBtn></center>
                            </MDBCardBody>
                        </MDBCard>
                        <MDBModalFooter>

                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            {/* **********************   LOADER START   ************************ */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}

            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* **********************   LOADER END   ************************ */}

            {/* ***********************************   OTP Verification Page     **************************** */}


            {/* FORGET PASSWORD */}

            <MDBModal tabIndex='-1' show={centredModal} setShow={setCentredModal}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader style={{ backgroundColor: '#04124f', fontWeight: 'bolder' }} className='text-white font-bold'>
                            <MDBModalTitle >Forgot Password</MDBModalTitle>
                            <MDBBtn className='btn-close' color='white' onClick={toggleShow}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBInput label='Please enter your Registered Email Address' value={forgotPassword} onChange={(e) => { setForgotPassword(e.target.value) }} id='typeURL' type='url' />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleShow}>
                                Close
                            </MDBBtn>
                            <MDBBtn onClick={forgotPasswordOfStudent}>Sent</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}

                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <p className="my-4 text-slate-500 text-lg leading-relaxed text-center">
                                        Login Credentials has been sent to your Registered
                                        <br />  Email-ID and Mobile No
                                    </p>
                                </div>
                                {/*footer*/}
                                <center className=" items-center  p-6 border-t border-solid border-slate-200 rounded-b">

                                    <button
                                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        OK
                                    </button>
                                </center>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}


        </div>
    )
}

export default Registration