import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Widget } from '@uploadcare/react-widget'


const Qualification = ({ data, onchange, value, route }) => {

    const navigate = useNavigate()
    const [photo, setPhoto] = useState('')
    const [signature, setSignature] = useState('')
    const [updatedList, setUpdatedList] = useState(false)
    const [studentData, setStudentData] = useState({})
    const [tenthBoard, setTenthBoard] = useState('')
    const [twelveBoard, setTwelveBoard] = useState('')
    const [gradBoard, setGradBoard] = useState('')
    const [tenthTotal, setTenthTotal] = useState('')
    const [twelveTotal, setTwelveTotal] = useState('')
    const [gradTotal, setGradTotal] = useState('')
    const [tenthObtained, setTenthObtained] = useState('')
    const [twelveObtained, setTwelveObtained] = useState('')
    const [gradObtained, setGradObtained] = useState('')
    const [checked, setChecked] = useState(false)
    const [preview, setPreview] = useState(false)
    const [optSmModal, setOptSmModal] = useState(false);
    const toggleShow = () => setOptSmModal(!optSmModal);
    //       UPDATE PHOTO AND SIGNATURE OF USER
    async function updatePhotoSignature() {
        // event.preventDefault()

        const response = await fetch('/document-update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId: studentData._id,
                photo,
                signature
            })
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log(data)
                if (data.message == "changes successfully") {
                    // alert('changes successfully')
                    // setCentredModal(false)
                    navigate('/fillApplication-step6')
                }
                else {
                    alert('Error')
                    // setCentredModal(false)
                }
            })
    }


    //   DOCUMENT UPLOAD THROUGH UPLOADCARE.Com

    // PHOTO
    const uploadFileChange = (info) => {
        if (info.size <= 100000) {
            onchange.setPhoto(info.cdnUrl)
        }
        else {
            alert('Please upload an Image within the specified size')
        }
    }
    const uploadFileSelect = (file) => {
        console.log(`file changed ${file}`)
        setUpdatedList(false)
        if (file) {
            file.progress((info) => console.log("file progress: ", info.progress))
            file.done((info) => setUpdatedList(true))
        }
    }
    // SIGNATURE
    const uploadFileChange2 = (info) => {


        if (info.size <= 50000) {
            onchange.setSignature(info.cdnUrl)
        }
        else {
            alert('Please upload an Image within the specified size')
        }
    }
    const uploadFileSelect2 = (file) => {
        console.log(`file changed ${file}`)
        setUpdatedList(false)
        if (file) {
            file.progress((info) => console.log("file progress: ", info.progress))
            file.done((info) => setUpdatedList(true))
        }
    }
    useEffect(() => {
        if (data.tenthBoard) {
            setPhoto(data.photo)
            setSignature(data.signature)
            setGradBoard(data.gradBoard)
            setTenthBoard(data.tenthBoard)
            setTwelveBoard(data.twelveBoard)
            setTenthObtained(data.tenthObtained)
            setTwelveObtained(data.twelveObtained)
            setGradObtained(data.gradObtained)
            setTenthTotal(data.tenthTotal)
            setTwelveTotal(data.twelveTotal)
            setGradTotal(data.gradTotal)
        }
    }, [data])
    return (
        <div className="overflow-x-auto relative">
            <div>
                <p className='font-bold text-black'>Academic Information</p>
                <p className='font-bold text-black text-[14px]'>
                    {/* Higher Secondary Academic Details <span className='text-red-600'>*</span> */}
                </p>
                <table className='border border-black text-[14px] w-[100%] overflow-x-scroll'>
                    <thead>
                        <tr className='border border-black'>
                            <th className='border pt-3 pb-3 pl-2 font-bold text-black border-black'>
                                Qualification
                            </th>
                            <th className='border pt-3 pb-3 pl-2 font-bold text-black border-black'>
                                School/Institute name
                            </th>
                            <th className='border pt-3 pb-3 pl-2 font-bold text-black border-black'>
                                Board
                            </th>
                            <th className='border pt-3 pb-3 pl-2 font-bold text-black border-black'>
                                Result Status
                            </th>
                            <th className='border pt-3 pb-3 pl-2 font-bold text-black border-black'>
                                Year Of Passing
                            </th>
                            <th className='border pt-3 pb-3 pl-2 font-bold text-black border-black'>
                                Obtained Percentage / CGPA
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='border text-center font-bold border-black'>
                                10th <sup><span className='text-red-600 '>*</span></sup>
                            </td>
                            <td className='border p-2 border-black'>
                                <input value={value.tenthSchool} onChange={(e) => { onchange.setTenthSchool(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none text-capitalize' placeholder="Ex: xyz School" type="text" />
                            </td>
                            <td className='border p-2 border-black'>
                                <input value={value.tenthBoard} onChange={(e) => { onchange.setTenthBoard(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none text-uppercase' type="text" />
                            </td>
                            <td className='border p-2 border-black'>
                                <select value={value.tenthResultStatus} onChange={(e) => { onchange.setTenthResultStatus(e.target.value) }} name="" id="" className='border w-[100%] p-[8px] outline-none border-black'>
                                    <option value="">--Select--</option>
                                    <option value="Awaited">Awaited</option>
                                    <option value="Declared">Declared</option>
                                </select>
                            </td>
                            <td className='border  p-2 border-black'>
                                <input value={value.tenthYOP} onChange={(e) => { onchange.setTenthYOP(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none text-capitalize' type="text" />
                            </td>
                            <td className='border p-2 border-black'>
                                <input value={value.tenthPer} onChange={(e) => { onchange.setTenthPer(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none text-capitalize' type="text" />
                            </td>
                        </tr>
                        <tr>
                            <td className='border text-center font-bold border-black'>
                                12th {route == "mba" && <sup><span className='text-red-600 '>*</span></sup>}
                            </td>
                            <td className='border p-2 border-black'>
                                <input value={value.twelveSchool} onChange={(e) => { onchange.setTwelveSchool(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none text-capitalize' placeholder="Ex:xyz School / College" type="text" />
                            </td>
                            <td className='border p-2 border-black'>
                                <input value={value.twelveBoard} onChange={(e) => { onchange.setTwelveBoard(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none text-uppercase' type="text" />
                            </td>
                            <td className='border p-2 border-black'>
                                <select name="" id="" value={value.twelveResultStatus} onChange={(e) => { onchange.setTwelveResultStatus(e.target.value) }} className='border w-[100%] p-[8px] outline-none border-black'>
                                    <option value="">--Select--</option>
                                    <option value="Awaited">Awaited</option>
                                    <option value="Declared">Declared</option>
                                </select>
                            </td>
                            <td className='border p-2 border-black'>
                                <input value={value.twelveYOP} onChange={(e) => { onchange.setTwelveYOP(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none text-capitalize' type="text" />
                            </td>
                            <td className='border p-2 border-black'>
                                <input value={value.twelvePer} onChange={(e) => { onchange.setTwelvePer(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none text-capitalize' type="text" />
                            </td>
                        </tr>
                        {
                            route == 'mba' &&

                            <tr>
                                <td className='border text-center font-bold border-black'>
                                    Graduation
                                </td>
                                <td className='border p-2 border-black'>
                                    <input value={value.gradSchool} onChange={(e) => { onchange.setGradSchool(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none text-capitalize' placeholder="Ex:xyz School / College" type="text" />
                                </td>
                                <td className='border p-2 border-black'>
                                    <input value={value.gradBoard} onChange={(e) => { onchange.setGradBoard(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none text-uppercase' type="text" />
                                </td>
                                <td className='border p-2 border-black'>
                                    <select name="" id="" value={value.gradResultStatus} onChange={(e) => { onchange.setGradResultStatus(e.target.value) }} className='border w-[100%] p-[8px] outline-none border-black'>
                                        <option value="">--Select--</option>
                                        <option value="Awaited">Awaited</option>
                                        <option value="Declared">Declared</option>
                                    </select>
                                </td>
                                <td className='border p-2 border-black'>
                                    <input value={value.gradYOP} onChange={(e) => { onchange.setGradYOP(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none text-capitalize' type="text" />
                                </td>
                                <td className='border p-2 border-black'>
                                    <input value={value.gradPer} onChange={(e) => { onchange.setGradPer(e.target.value) }} className='border border-black w-[100%] p-[7px] outline-none text-capitalize' type="text" />
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>

            <div className='grid sm:flex justify-between mt-4' style={{ width: '100%', }}>
                <div className="col-md-5  gap-2 ">
                    <div>
                        <p className='text-[14px] text-black font-bold'>Upload Your Recent Passport Size Photograph <sup className='text-red-600 text-[16px]'>*</sup></p>
                        <div className='mt-[-10px] border border-black  text-black p-2 '>
                            <Widget

                                className='text-black '
                                publicKey="b0a09355a6bf471e30e8"
                                id="file"
                                crop='1:1'
                                clearable={true}
                                onChange={(info) => uploadFileChange(info)}
                                onFileSelect={(file) => { uploadFileSelect(file) }}
                                tabs="file" tabsCss=".source-file { background: #1877F2;color:black; }"
                            />

                        </div>
                        <span className='text-[12px] text-blue-600'>Max Upload Limit 100 KB</span>
                    </div>
                    {
                        value.photo &&

                        <div>
                            <img style={{ width: '150px', height: '150px', margin: 'auto', marginTop: '1rem' }} className='border border-black p-2' src={value.photo} alt="" />

                        </div>
                    }
                </div>
                <div className="col-md-5     gap-4">
                    <div>
                        <p className='text-[14px] text-black font-bold'>Upload Your Signature <sup className='text-red-600 text-[16px]'>*</sup></p>

                        <div className='mt-[-10px] border border-black  text-black p-2 flex'>
                            <Widget
                                className='text-black'
                                publicKey="b0a09355a6bf471e30e8"
                                id="file"
                                crop='5:2'
                                clearable={true}
                                onChange={(info) => uploadFileChange2(info)}
                                onFileSelect={(file) => { uploadFileSelect2(file) }}
                                tabs="file" tabsCss=".source-file { background: #1877F2;color:black; }"

                            />
                        </div>
                        <span className='text-[12px] text-blue-600'>Max Upload Limit 50 KB</span>
                    </div>
                    {
                        value.signature &&
                        <div style={{ width: '150px', height: '150px', margin: 'auto', verticalAlign: 'center', marginTop: "1rem" }} className='border border-black'>


                            <img style={{ margin: 'auto', marginTop: '2.6rem' }} className='' src={value.signature} alt="" />
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default Qualification