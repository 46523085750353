import React, { useState, useEffect, useRef } from 'react'
import { MDBIcon, MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody } from 'mdb-react-ui-kit'
import { useNavigate } from 'react-router-dom'
import { useReactToPrint } from "react-to-print";
import mbaPgdm from '../../../src/pdf/Eligibility_Criteria_for_MBA.pdf'
import Eligibility_Criteria_for_MBA from '../../pdf/Eligibility_Criteria_for_MBA.pdf'
import EligibilityCriteriaforAllOther from '../../pdf/EligibilityCriteriaforAllOther.pdf'
import EligibilityCriteriaforEngineering from '../../pdf/EligibilityCriteriaforEngineering.pdf'
import EligibilityCriteriaforPCB from '../../pdf/EligibilityCriteriaforPCB.pdf'
import EligibilityCriteriaforPCM from '../../pdf/EligibilityCriteriaforPCM.pdf'
// import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import AcknowledgeMentPrint from '../MultiStepForm/AcknowledgeMentPrint';
import AcknowledgeMentPrintEngg from '../MultiStepForm/Engineering/AcknowledgeMentPrint';
import AcknowledgeMentPrintPCM from '../MultiStepForm/PCM/AcknowledgeMentPrint';
import AcknowledgeMentPrintPCB from '../MultiStepForm/PCB/AcknowledgeMentPrint';
import AcknowledgeMentPrintOther from '../MultiStepForm/Other/AcknowledgeMentPrint';
import application from './Application/applicationTitle'
import ApplicationControl from './Application/ApplicationControl';
import applicationTitle from './Application/applicationTitle';
function AllApplication() {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate()
    const [apply, setAppply] = useState('Apply Now')
    const [continues, setContinue] = useState('Continue')
    const [closed, setClosed] = useState('Closed')
    const [download, setDownload] = useState('Download')
    const [percentComplete, setPercentComplete] = useState()
    const [studentData, setStudentData] = useState({})
    const [paymentDate, setPaymentDate] = useState('')
    const [paymentMethod, setPaymentMethod] = useState('')
    const [amount, setAmount] = useState('')
    const [transactionId, setTransactionId] = useState('')
    const [status, setStatus] = useState('')
    const [mbaAcknow, setMbaAcknow] = useState(false)
    const [enggAcknow, setEnggAcknow] = useState(false)
    const [pcmAcknow, setPcmAcknow] = useState(false)
    const [pcbAcknow, setPcbAcknow] = useState(false)
    const [otherAcknow, setOtherAcknow] = useState(false)
    const [optSmModal, setOptSmModal] = useState(false);
    const toggleShow1 = () => setOptSmModal(!optSmModal);
    const [optSmModal2, setOptSmModal2] = useState(false);
    const toggleShow2 = () => setOptSmModal2(!optSmModal2);
    const [optSmModal3, setOptSmModal3] = useState(false);
    const toggleShow3 = () => setOptSmModal3(!optSmModal3);
    const [optSmModal4, setOptSmModal4] = useState(false);
    const toggleShow4 = () => setOptSmModal4(!optSmModal4);
    const [optSmModal5, setOptSmModal5] = useState(false);
    const toggleShow5 = () => setOptSmModal5(!optSmModal5);
    //        USER AUTHENTICATION            //
    const userAuthenticationData = async () => {
        fetch('/user-authentication-data')
            .then(response => response.json())
            .then(data => {
                setStudentData(data)
                if (data.educationDetails) {
                    setPercentComplete(80)
                }
                else if (data.personalDetails) {
                    setPercentComplete(50)
                }
                
            })
            .catch(error => {
                console.log(error)
            })
    }
    const userAuthenticationData2 = async () => {
        fetch('/user-authentication-data')
            .then(response => response.json())
            .then(data => {
                if (data.paymentResponseMBA) {
                    const payment = data.paymentResponseMBA[data.paymentResponseMBA.length - 1].paymentStatus
                    const paymentTrim = payment.trim().split("&")
                    const transDate = paymentTrim[40].trim().split('=')
                    const paymentMode = paymentTrim[5].trim().split('=')
                    const paymentAmount = paymentTrim[10].trim().split('=')
                    const paymentTransaction = paymentTrim[1].trim().split('=')
                    const orderStatus = paymentTrim[3].trim().split('=')
                    setPaymentDate(transDate[1])
                    setPaymentMethod(paymentMode[1])
                    setAmount(paymentAmount[1])
                    setTransactionId(paymentTransaction[1])
                    setStatus(orderStatus[1])
                    // console.log(paymentTrim)
                    // console.log(transDate)



                }
                else {
                    // console.log('no')
                }
            })
            .catch(error => {
                console.log(error)
            })
    }



    ///   print functionallity
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        pageStyle: "@page { margin-top: 50px;  } ",
        content: () => componentRef.current,
    });

    //    MBA APPLICATION OPEN FUNCTIONALLITY
    const [fullscreenXlModal, setFullscreenXlModal] = useState(false);

    const toggleShow = () => setFullscreenXlModal(!fullscreenXlModal);
    useEffect(() => {
        userAuthenticationData()
        userAuthenticationData2()
    }, [])

    const application = async (prog) => {
        await fetch(`/api/v2/application/${prog}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    userAuthenticationData()
                }
                else {
                    alert(data.message)
                }

            })

    }
    return (
        <div className='paymentPage' style={{ width: '100%', border: '', margin: 'auto' }}>
            <table className='bg-white' style={{ width: '99%', marginLeft: '5px' }}>
                <tr>
                    <th style={{ border: '1px solid #bdc2c7', width: '36%', padding: '0.7rem 1rem', fontSize: '13px', color: 'black' }}>Application Form</th>
                    <th style={{ border: '1px solid #bdc2c7', width: '16%', textAlign: 'center', padding: '0.7rem 1rem', fontSize: '13px', color: 'black' }}>Application No.</th>
                    <th style={{ border: '1px solid #bdc2c7', width: '16%', textAlign: 'center', padding: '0.7rem 1rem', fontSize: '13px', color: 'black' }}>Application Fees</th>
                    <th style={{ border: '1px solid #bdc2c7', width: '16%', textAlign: 'center', padding: '0.7rem 1rem', fontSize: '13px', color: 'black' }}>Eligibility Criteria & Syllabus</th>
                    <th style={{ border: '1px solid #bdc2c7', width: '16%', textAlign: 'center', padding: '0.7rem 1rem', fontSize: '13px', color: 'black' }}>Action</th>
                </tr>
            </table>
            <p className="fw-semibold mt-4 pl-3">Post Graduate Programs</p>
            <ApplicationControl percentage={studentData.mba && studentData.mba.examSlot && percentComplete+10} route="mba" eligibility={Eligibility_Criteria_for_MBA} title={applicationTitle.mba} applicationNo={studentData.mba && studentData.mba.applicationNo} application={application} isApplicationCompleted={studentData.mba && studentData.mba.payStatus} applicationTitle="PG (MBA/PGDM)"/>
            {/* <hr class="border border-dark border-1 opacity-40" /> */}


            <p className="fw-semibold mt-4 pl-3">Under Graduate Programs</p>
            <ApplicationControl percentage={studentData.engg && studentData.engg.examSlot && percentComplete+10} route="engg" eligibility={EligibilityCriteriaforEngineering} title={applicationTitle.engg} applicationNo={studentData.engg && studentData.engg.applicationNo} application={application} isApplicationCompleted={studentData.engg && studentData.engg.payStatus} applicationTitle="Engineering UG " />


            <p className="fw-semibold mt-4 pl-3">Under Graduate <span className='text-sm font-bold' style={{ color: '#042e2e', fontWeight: 'bolder' }}>(PCM Students only)</span></p>
            <ApplicationControl percentage={studentData.pcm && studentData.pcm.examSlot && percentComplete+10} route="pcm" eligibility={EligibilityCriteriaforPCM} title={applicationTitle.pcm} applicationNo={studentData.pcm && studentData.pcm.applicationNo} application={application} isApplicationCompleted={studentData.pcm && studentData.pcm.payStatus} applicationTitle="PCB UG "/>
            
           
            <p className="fw-semibold mt-4 pl-3">Under Graduate <span className='text-sm font-bold' style={{ color: '#042e2e', fontWeight: 'bolder' }}>(PCB Students only)</span></p>
            <ApplicationControl percentage={studentData.pcb && studentData.pcb.examSlot && percentComplete+10} route="pcb" eligibility={EligibilityCriteriaforPCB} title={applicationTitle.pcb} applicationNo={studentData.pcb && studentData.pcb.applicationNo} application={application} isApplicationCompleted={studentData.pcb && studentData.pcb.payStatus} applicationTitle="PCM UG "/>


            
            <p className="fw-semibold mt-4 pl-3">Under Graduate <span className='text-sm font-bold' style={{ color: '#042e2e', fontWeight: 'bolder' }}>(All Other Program)</span></p>
            <ApplicationControl percentage={studentData.other && studentData.other.examSlot && percentComplete+10} route="other" eligibility={EligibilityCriteriaforAllOther} title={applicationTitle.other} applicationNo={studentData.other && studentData.other.applicationNo} application={application} isApplicationCompleted={studentData.other && studentData.other.payStatus} applicationTitle="Other UG "/>
            
        </div>
    )
}

export default AllApplication