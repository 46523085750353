import React, { useState, useRef, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import { MDBModalHeader } from 'mdb-react-ui-kit'
import styled from 'styled-components';
import { useParams } from 'react-router-dom'
function Testing({ studentData }) {
    // const [studentData, setStudentData] = useState({})
    const { program } = useParams()
    const [optSmModal, setOptSmModal] = useState(true);
    const toggleShow = () => setOptSmModal(!optSmModal);
    // useEffect(() => {
    //    setStudentData(data)
    //   }, [])
    // console.log(studentData)
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [data, setData] = useState()
    const userAuthenticationData = async () => {
        fetch('/user-authentication-data')
            .then(response => response.json())
            .then(data => {
                setData(data)
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        userAuthenticationData()
    }, [])



    return (
        <div className='' ref={componentRef}>
            {
                data &&

                <AcknowledgeMentCSS>
                    <div className='border border-black text-center' >
                        <center><img className='w-[200px] mb-2' src="https://ucarecdn.com/310f986c-7286-41bf-9bfd-79673ec9eff1/AICEE2023LOGO4.png" alt="logo" /></center>
                        <div style={{ lineHeight: '3px' }}>
                            <p className='text[14px] font-bold text-black'>{program == "mba" ? "Postgraduate" : "Undergraduate"} Application Form - 2024</p>
                            <p className='text[14px] font-bold text-black '>Application No: {data[program].applicationNo}</p>
                        </div>
                    </div>
                    <div className='mt-[7px] border border-black' style={{ width: '100%' }}>
                        <p className='border border-black text-black font-bold  pt-[3px] pb-[3px] text-[13px]'>
                            Application Details
                        </p>
                        <p className='border border-black text-black font-bold  pt-[3px] pb-[3px] mt-[-17px] text-[13px]'>
                            Personal Details
                        </p>
                        <div className=''>
                            <table className='mt-[-17px] p-2 text-[13px] w-[100%]'>
                                <tr className='w-[100%]'>
                                    <td className='border border-black font-bold text-black'>Name</td>
                                    <td className='border border-black text-black'>{data.name}</td>
                                    <td className='border border-black font-bold text-black'>Email</td>
                                    <td className='border border-black text-black text-lowercase'>{data.email}</td>
                                    <td className='border border-black text-black' rowSpan={4} colSpan={4}><img className='w-[100px] m-auto p-2 border border-black' src={data.educationDetails.photo} alt="" /></td>

                                </tr>
                                <tr>
                                    <td className='border border-black font-bold text-black'>Mobile No</td>
                                    <td className='border border-black text-black'>+91 {data.mobileNo}</td>
                                    <td className='border border-black font-bold text-black'>Country</td>
                                    <td className='border border-black text-black'>India</td>
                                </tr>
                                <tr>
                                    <td className='border border-black font-bold text-black'>Gender</td>
                                    <td className='border border-black text-black'>{data.gender}</td>
                                    <td className='border border-black font-bold text-black'>DOB</td>
                                    <td className='border border-black text-black'>{data.personalDetails.dob}</td>
                                </tr>
                                <tr>
                                    <td className='border border-black font-bold text-black'>Father's Name</td>
                                    <td className='border border-black text-black'>{data.personalDetails.fatherName}</td>
                                    <td className='border border-black font-bold text-black'>Mother's Name</td>
                                    <td className='border border-black text-black'>{data.personalDetails.motherName}</td>
                                </tr>
                                <tr>
                                    <td className='border border-black font-bold text-black'>Guardian's Phone Number</td>
                                    <td className='border border-black text-black'>+91 {data.personalDetails.guardianNo}</td>
                                    <td className='border border-black font-bold text-black'>Alternate Phone number</td>
                                    <td className='border border-black text-black'>+91 {data.personalDetails.alternateNo}</td>
                                    <td className='border border-black text-black' rowSpan={1} colSpan={4}><img className='w-[100px] h-[30px] m-auto  border border-black' src={data.educationDetails.signature} alt="" /></td>
                                </tr>
                                <tr>
                                    <td className='border border-black font-bold text-black'>Blood Group</td>
                                    <td className='border border-black text-black'>{data.personalDetails.bloodGroup}</td>
                                    <td className='border border-black font-bold text-black'>Category</td>
                                    <td className='border border-black text-black'>{data.personalDetails.category}</td>
                                    <td className='border border-black text-black font-bold'>EWS</td>
                                    <td className='border border-black text-black'>{data.personalDetails.ews}</td>
                                </tr>
                                <tr>
                                    <td className='border border-black font-bold text-black'>Religion</td>
                                    <td className='border border-black text-black'>{data.personalDetails.religion}</td>
                                    <td className='border border-black font-bold text-black'>Aadhaar</td>
                                    <td className='border border-black text-black' colSpan={3}>{data.personalDetails.aadhaarNo}</td>
                                </tr>
                                <tr>
                                    <td colSpan={6} className='font-bold text-[15px] text-black '>Correspondence Address</td>
                                </tr>
                                <tr>
                                    <td className='border border-black font-bold text-black'>Address Line 1</td>
                                    <td className='border border-black text-black' colSpan={5}>{data.personalDetails.addressLine1} </td>
                                </tr>
                                <tr>
                                    <td className='border border-black font-bold text-black'>Address Line 2</td>
                                    <td className='border border-black text-black' colSpan={5}>{data.personalDetails.addressLine2} </td>
                                </tr>
                                <tr>
                                    <td className='border border-black font-bold text-black'>City</td>
                                    <td className='border border-black text-black'>{data.personalDetails.city}</td>
                                    <td className='border border-black font-bold text-black'>State</td>
                                    <td className='border border-black text-black'>{data.personalDetails.state}</td>
                                    <td className='border border-black font-bold text-black'>Pincode</td>
                                    <td className='border border-black text-black' colSpan={5}>{data.personalDetails.pincode} </td>

                                </tr>
                                <tr>
                                    <td colSpan={6} className='font-bold text-[15px] text-black '>Permanent Address</td>
                                </tr>
                                <tr>
                                    <td className='border border-black font-bold text-black'>Address Line 1</td>
                                    <td className='border border-black text-black' colSpan={5}>{data.personalDetails.paddressLine1} </td>
                                </tr>
                                <tr>
                                    <td className='border border-black font-bold text-black'>Address Line 2</td>
                                    <td className='border border-black text-black' colSpan={5}>{data.personalDetails.paddressLine2} </td>
                                </tr>
                                <tr>
                                    <td className='border border-black font-bold text-black'>City</td>
                                    <td className='border border-black text-black'>{data.personalDetails.pcity}</td>
                                    <td className='border border-black font-bold text-black'>State</td>
                                    <td className='border border-black text-black'>{data.personalDetails.pstate}</td>
                                    <td className='border border-black font-bold text-black'>Pincode</td>
                                    <td className='border border-black text-black' colSpan={5}>{data.personalDetails.ppincode} </td>

                                </tr>
                            </table>
                            <table className=' p-2 text-[13px] w-[100%]'>
                                <tr>
                                    <td colSpan={6} className='font-bold text-[15px] text-black '> Examination Details</td>
                                </tr>

                                <tr>
                                    <td className='border border-black font-bold text-black'>Program Interested</td>
                                    <td className='border border-black text-black'>{data[program].program}</td>
                                    <td className='border border-black font-bold text-black'>Examination Mode</td>
                                    <td className='border border-black text-black'>{data[program].examinationMode} </td>
                                </tr>
                                <tr>
                                    {
                                        data[program].branchPref &&
                                        <>
                                            <td className='border border-black font-bold text-black'>Branch Preference</td>
                                            <td className='border border-black text-black'>{data[program].branchPref} </td>
                                        </>
                                    }
                                    <td className='border border-black font-bold text-black'>Exam Slot</td>
                                    <td className='border border-black text-black'>{data[program].examSlot}</td>
                                </tr>
                            </table>
                            <table className=' p-2 text-[13px] w-[100%]'>
                                <tr>
                                    <td colSpan={6} className='font-bold text-[15px] text-black '>Education Qualification Details</td>
                                </tr>
                                <tr>
                                    <td style={{ height: '3rem' }} className='border border-black font-bold text-black'>Qualification</td>
                                    <td className='border border-black font-bold text-black'>Name of Institute</td>
                                    <td className='border border-black font-bold text-black'>Board</td>
                                    <td className='border border-black font-bold text-black'>Result Status</td>
                                    <td className='border border-black font-bold text-black'>Year of Passing</td>
                                    <td className='border border-black font-bold text-black'>Obtained Percentage / CGPA</td>

                                </tr>
                                <tr>
                                    <td style={{ height: '3rem' }} className='border border-black font-bold  text-black'>10th</td>
                                    <td className='border border-black  text-black'>{data.educationDetails.tenthSchool}</td>
                                    <td className='border border-black  text-black'>{data.educationDetails.tenthBoard}</td>
                                    <td className='border border-black  text-black'>{data.educationDetails.tenthResultStatus}</td>
                                    <td className='border border-black  text-black'>{data.educationDetails.tenthYOP}</td>
                                    <td className='border border-black  text-black'>{data.educationDetails.tenthPer}</td>
                                </tr>
                                <tr>
                                    <td style={{ height: '3rem' }} className='border border-black font-bold  text-black'>12th</td>
                                    <td className='border border-black  text-black'>{data.educationDetails.twelveSchool}</td>
                                    <td className='border border-black  text-black'>{data.educationDetails.twelveBoard}</td>
                                    <td className='border border-black  text-black'>{data.educationDetails.twelveResultStatus}</td>
                                    <td className='border border-black  text-black'>{data.educationDetails.twelveYOP}</td>
                                    <td className='border border-black  text-black'>{data.educationDetails.twelvePer}</td>
                                </tr>
                                <tr>
                                    <td style={{ height: '3rem' }} className='border border-black font-bold  text-black'>Graduation</td>
                                    <td className='border border-black  text-black'>{data.educationDetails.gradSchool}</td>
                                    <td className='border border-black  text-black'>{data.educationDetails.gradBoard}</td>
                                    <td className='border border-black  text-black'>{data.educationDetails.gradResultStatus}</td>
                                    <td className='border border-black  text-black'>{data.educationDetails.gradYOP}</td>
                                    <td className='border border-black  text-black'>{data.educationDetails.gradPer}</td>
                                </tr>
                            </table>

                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <table className=' p-2 text-[13px] w-[100%]'>
                        <tr>
                            <td colSpan={6} className='font-bold text-[15px] text-black '>Payment Details</td>
                        </tr>

                        <tr>
                            <td className='border border-black font-bold text-black'>Payment Mode</td>
                            <td className='border border-black text-black'>{data[program].payMode}</td>
                            <td className='border border-black font-bold text-black'>Transaction ID</td>
                            <td className='border border-black text-black'>{data[program].transId} </td>

                        </tr>
                        <tr>
                            <td className='border border-black font-bold text-black'>Transaction Date</td>
                            <td className='border border-black text-black'>{data[program].payDate}</td>
                            <td className='border border-black font-bold text-black'>Amount</td>
                            <td className='border border-black text-black' colSpan={3}>{data[program].amount}</td>
                        </tr>
                    </table>
                    
                    <table className='border border-black p-2 text-[13px] w-[100%]'>
                        <tr className='border border-black'>
                            <td colSpan={6} className='font-bold text-[15px] text-black border border-black text-center'>List of Documents to be Carried at the time of reporting to the Institution.</td>
                        </tr>
                        <tr className='border border-black'>
                            <td className='w-[100px] font-bold text-[15px] text-black border border-black'>S.NO</td>
                            <td colSpan={5} className='font-bold text-[15px] text-black border border-black'>Document</td>
                        </tr>
                        <tr className='border border-black'>
                            <td className='w-[100px]  text-[15px] text-black border border-black'>1</td>
                            <td colSpan={5} className=' text-[15px] text-black border border-black'>1Oth MARKSHEET</td>
                        </tr>
                        <tr className='border border-black'>
                            <td className='w-[100px]  text-[15px] text-black border border-black'>2</td>
                            <td colSpan={5} className=' text-[15px] text-black border border-black'>12th MARKSHEET</td>
                        </tr>
                        <tr className='border border-black'>
                            <td className='w-[100px]  text-[15px] text-black border border-black'>3</td>
                            <td colSpan={5} className=' text-[15px] text-black border border-black'>UG MARKSHEET ALL SEMESTER/CONSOLIDATED(PG
                                ADMISSION)</td>
                        </tr>
                        <tr className='border border-black'>
                            <td className='w-[100px]  text-[15px] text-black border border-black'>4</td>
                            <td colSpan={5} className=' text-[15px] text-black border border-black'>PROVISONAL/DEGREE CERTIFICATE</td>
                        </tr>
                        <tr className='border border-black'>
                            <td className='w-[100px]  text-[15px] text-black border border-black'>5</td>
                            <td colSpan={5} className=' text-[15px] text-black border border-black'>TC</td>
                        </tr>
                        <tr className='border border-black'>
                            <td className='w-[100px]  text-[15px] text-black border border-black'>6</td>
                            <td colSpan={5} className=' text-[15px] text-black border border-black'>MIGERATION CERTIFICATE</td>
                        </tr>
                        <tr className='border border-black'>
                            <td className='w-[100px]  text-[15px] text-black border border-black'>7</td>
                            <td colSpan={5} className=' text-[15px] text-black border border-black'>CASTE CERTIFICATE-SC/ST/OBC</td>
                        </tr>
                        <tr className='border border-black'>
                            <td className='w-[100px]  text-[15px] text-black border border-black'>8</td>
                            <td colSpan={5} className=' text-[15px] text-black border border-black'>PHOTOS -3</td>
                        </tr>
                        <tr className='border border-black'>
                            <td className='w-[100px]  text-[15px] text-black border border-black'>9</td>
                            <td colSpan={5} className=' text-[15px] text-black border border-black'>AADHAAR</td>
                        </tr>
                    </table>
                    <table className=' p-2 text-[13px] w-[100%]'>
                        <tr>
                            <td colSpan={6} className='font-bold text-[15px] text-black text-center'>Declaration</td>
                        </tr>

                        <tr>
                            <td className='border border-black  text-black'><input type="checkbox" checked={true} /> I do hereby declare that All the Information provided by me here are true and correct to the best of my knowledge, I do Further undertake that in case any of the Information provided found to be Incorrect at any stage. My Application can be cancelled, I also Agree to Abide by the rules of the Institution. In case of Indiscipline or Disobedience of Rules, I shall be Liable for Expelation from the Institute, the Organisation has the right to use Information for communication purposes.</td>

                        </tr>
                        <tr>
                            <td className='border border-black  text-black'><input type="checkbox" checked={true} /> I accept this application fee is Non - Refundable.</td>

                        </tr>
                        <tr>
                            <td className='border border-black  text-black'><input type="checkbox" checked={true} /> I Accept , I will submit all the necessary documents required by the Institution before Final Allotment or at the time of reporting to the Institution.</td>

                        </tr>

                    </table>
                    <center><button onClick={() => { window.print() }} className='border border-black pl-3 pr-3 mt-4 mb-4'>Print</button></center>


                </AcknowledgeMentCSS>
            }
        </div>
    )
}

const AcknowledgeMentCSS = styled.main`
    *{
        font-family: "Sofia", sans-serif;
        text-transform: capitalize;
    }
`

export default Testing