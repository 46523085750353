import React from 'react'
import LeftBar from './LeftBar'
// import CentreBar from './CentreBar'
import RightSideBar from './RightSideBar'
import newicon from './img/newicon.gif'
import './css/own.css'
import { useNavigate, Link } from 'react-router-dom'
function Home() {
    const navigate = useNavigate()

    return (
        <div>
            <section className="contentsection mt-2" id="skipcontent">
                <div className="container-fluid">
                    <div className="row">

                        {/* <!-- LEFT SIDEBAR START HERE --> */}
                        <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                            <LeftBar />
                        </div>
                        {/* </div> <!-- LEFT SIDEBAR END HERE --> */}


                        {/* <!-- INTRO SECTION START HERE --> */}
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="mb-4">
                                <div className="mb-4 pagecontentpara LandingPara">
                                    {/* <h5 className="pageheading" style={{fontWeight:'500'}}>About AICEE (ALL INDIA COMBINED ENTRANCE EXAMINATION)</h5> */}
                                    <h5 className="pageheading text-black text-md">
                                        About AICEE (ALL INDIA COMBINED ENTRANCE EXAMINATION)
                                    </h5>
                                    <p align="justify">AICEE (All India Combined Entrance Examination ) is a national level combined entrance examination which is
                                        conducted with an association of 1200+ Colleges and 250 + Universities of India for admission in Different
                                        Undergraduate and Postgraduate programs that they are offering through one common application . AICEE
                                        Examination is Designed in such a way that after appearing in AICEE Examination students don&#39;t have to appear for
                                        multiple examinations of different States and Universities .
                                    </p>
                                    <p style={{ marginTop: '-0.5rem' }} align="justify">AICEE 2024 Examination will be conducted Online in Two different Modes : First one is Home Based Examination
                                        Mode ( In this mode Students can appear for AICEE Examination by just sitting at their Home using their Laptop ,
                                        Desktop , Tablets and Smartphones , Students can even choose their Examination Slot &amp; Timings at the time of
                                        Application ) . Second Mode is Centre Based Online Examination Mode ( In this mode students can appear for
                                        AICEE Examination By Selecting their examination city ) . </p>
                                    <p style={{ marginTop: '-0.5rem' }} align="justify">Entire process of AICEE begins from Application, Examination , College choice filling till college reporting will be
                                        done Online so students don&#39;t have to travel much. This Will save a lot of time, effort &amp; money . AICEE Will also
                                        provide Rank wise fees waiver for meritorious students . No other Examination in India will give this much range of
                                        College through one single Application . There are many more advantages of AICEE.</p>
                                    <p align="justify" style={{ fontSize: '13px', color: 'black', fontWeight: '400', marginTop: '-0.5rem' }}>
                                        All these Advantages make AICEE a unique and Must Apply Examination to get admission in 1200 + Colleges &amp;
                                        250+ Universities of India for 20+ programs. These Programs are  <strong>Engineering 
                                            ( B.Tech/ B.E) , MBA/PGDM, BCA,
                                            Pharmacy ,Bio- Technology &nbsp; <Link to="/about" >Read More..</Link>
                                        </strong>
                                    </p>
                                    
                                </div>
                            </div>
                            {/* <div className="mt-2">
                                <nav id="" className=''>
                                    <Link to="#">

                                        <div className='cursor-pointer text-center pt-2 pb-2 rounded-md' style={{ backgroundColor: '#00468A', fontSize: '14px' }}>
                                            <span className='flex justify-center text-white'><span>
                                                Application Closed for Phase - 1
                                            </span>&nbsp; &nbsp;<span><img src={newicon} alt="" /></span></span>
                                        </div>
                                    </Link>
                                </nav>
                                <br />
                            </div> */}
                            <div className="mt-2">
                                <nav id="" className=''>
                                    <Link to="#">
                                        {/* <Link to="/registration"> */}

                                        <div className='cursor-pointer text-center pt-2 pb-2 rounded-md' style={{ backgroundColor: '#00468A', fontSize: '14px' }}>
                                            <span className='flex justify-center text-white'><span>
                                                {/* AICEE 2024 Phase 4 Application is Open now ( Click here to Apply ) . */}
                                                AICEE 2024 Phase 4 Applications are Closed .
                                            </span>&nbsp; &nbsp;<span><img src={newicon} alt="" /></span></span>
                                        </div>
                                    </Link>
                                </nav>
                                <nav id="" className='mt-2'>
                                    <Link to="/nri">
                                        {/* <Link to="/registration"> */}

                                        <div className='cursor-pointer text-center pt-2 pb-2 rounded-md' style={{ backgroundColor: '#00468A', fontSize: '14px' }}>
                                            <span className='flex justify-center text-white'><span>
                                                Click here to Apply as NRI Category / Foreign Candidates .
                                            </span>&nbsp; &nbsp;<span><img src={newicon} alt="" /></span></span>
                                        </div>
                                    </Link>
                                </nav>
                                <br />
                            </div>
                        </div>
                        {/* </div> <!-- INTRO SECTION END HERE --> */}


                        {/* <!-- RIGHT SIDEBAR START HERE --> */}
                        <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                            <RightSideBar />
                        </div>
                        {/* <!-- RIGHT SIDEBAR END HERE --> */}

                    </div>
                </div>
            </section>


        </div>
    )
}

export default Home