import React, { useState, useEffect } from 'react'
import Header from '../Pages/LandingPage/Header'
import Rank from './RankCss'
import { MDBIcon, MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody } from 'mdb-react-ui-kit'
import Card from '../Pages/RankCard/Card';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Captcha from './Captcha';
function RankCard() {
    const [optSmModal, setOptSmModal] = useState(false);
    const [applicationNo, setApplicationNo] = useState("")
    const [dob, setDob] = useState("")
    console.log(dob)
    const toggleShow1 = (e) => {
        e.preventDefault()
        setOptSmModal(!optSmModal);
        // onSubmit()
    }

    // captcha code
    const [user, setUser] = useState({
        username: ""
    });
    console.log(user)
    const characters = 'abc123';

    function generateString(length) {
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;

    }
    const captcha = generateString(6) // Function called here and save in captcha variable

    let handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        user[name] = value;
        setUser(user);
    }
    const onSubmit = e => {
        e.preventDefault()
        var element = document.getElementById("succesBTN");
        var inputData = document.getElementById("inputType");
        // element.style.cursor = "wait"; 
        // element.innerHTML = "Checking...";
        // inputData.disabled = true;
        // element.disabled = true;
        var myFunctions = function () {
            if (!applicationNo || !dob) {
                alert('All Field are Mandatory')
            }
            else {
                if (captcha == user.username) {


                    // alert('captcha matched')
                    rankCardData()
                    // setOptSmModal(true)
                }
                else {


                    //  element.disabled = true;
                    var myFunction = function () {


                        element.disabled = false;
                        inputData.disabled = false;
                        inputData.value = 'sssss';
                        console.log(captcha)
                    };
                    alert('captcha not matched')
                    setTimeout(myFunction, 1000);
                }
            }
        }
        setTimeout(myFunctions, 500);
    };

    const [data, setData] = useState()
    // /student/rankcard
    async function rankCardData() {
        // event.preventDefault()


        const response = await fetch('https://admin.aicee.in/counselling/rank-card', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                credentials: applicationNo, password: dob
            })
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    if(data.data.length == 0){
                        alert('Rusult Not Found')
                    }
                    else{
                        setData(data.data[0])
                        setOptSmModal(true)
                    }
                }
                else {
                    alert(data.message)
                }
            })
    }

    return (
        <Rank>
            <Header />
            <div className="mt-2 bg-white sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden flex flex-col items-center shadow-lg"> {/* Container */}

                {/* :CONTAINER */}
                <div className="px-4 py-4 sm:px-10 w-full">

                    {/* ::Sign In Title */}
                    <h2 className="py-1 text-lg font-semibold text-center text-[#e35d26]">AICEE - 2024 (Phase-4) Result</h2>

                    {/* ::External Authentification */}
                    <div className="flex justify-center text-gray-600">

                    </div>



                    {/* ::Sign Up Form */}
                    <form >

                        {/* Email */}
                        <label htmlFor="email" className="text-lg">
                            <input type="text" name="email" id="email" onChange={(e) => { setApplicationNo(e.target.value) }} placeholder="Application No / Email-Id / Mobile No" className="form-input my-3   rounded-md shadow-sm  " style={{textTransform:'None'}} required />
                        </label>
                        <br />
                        {/* Password */}
                        <label htmlFor="password" className="text-lg"> 
                            <input id="password" name="password" onChange={(e) => { setDob(e.target.value) }} type="password" autoComplete='off' placeholder="Password" className="form-input my-3 block w-full border-gray-300 rounded-md shadow-sm  focus:ring-red-700 focus:border-red-700" required />
                        </label>
                        <Captcha handleChange={handleChange} captcha={captcha} />

                        {/* Button Create your account */}
                        <button onClick={onSubmit} className="w-full flex justify-center mt-3 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm text-white font-semibold uppercase tracking-widest bg-[#e35d26] hover:bg-[#a94c26] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                            Submit
                        </button>
                        {/* <p onClick={toggleShow1}  className='text-center text-sm pt-4 cursor-pointer'> <span><i className='fa fa-print'/></span> Print / Download Application Form <span className='font-bold'></span></p> */}
                    </form>
                </div>

            </div>
            <center>
                {/* <strong style={{ color: 'red' }}>Note :- </strong> <span style={{ fontSize: '13px' }}>
                All Results are published now, Move to AICEE 2023 .  Where you will be able To login after 3:00 PM Today.
                </span> */}
            </center>
            <MDBModal show={optSmModal} tabIndex='-1' setShow={setOptSmModal}>
                <MDBModalDialog size='lg'>
                    <MDBModalContent>
                        <MDBModalHeader>
                            {/* <MDBModalTitle className='text-black font-bold'>Preview Of Your Application</MDBModalTitle> */}
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow1}></MDBBtn>
                        </MDBModalHeader>

                        <MDBModalBody>

                            <Card data={data} />

                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </Rank>
    )
}

export default RankCard