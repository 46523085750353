const applicationTitle =
{
    mba: "MBA / PGDM Application Form 2024",
    engg: "Engineering ( B-Tech / B.E )",
    pcm: `
        Bio Technology ( Bsc. BioTechnology / B-Tech BioTechnology ) ,
                        B.Pharmacy ,
                        Agriculture ( Bsc. Agriculture / B-Tech Agriculture ) ,
                        Bsc Datascience
        `,
    pcb:`
    B.Pharmacy ,
                        Bsc.Nursing ,
                        Bsc.Agriculture  ,
                        Bio-Tech ( Bsc.BioTech / B-Tech.BioTech)

    `,
    other:`
    BBA ,
    BCA ,
    B.Design ,
    LLB (BA / B.Com / B.sc / BBA / BCA)  ,
    Mass Communication & Journalism
    `
}

module.exports = applicationTitle
