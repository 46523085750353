import React from 'react'
import newicon from './img/newicon.gif'
import horn from './img/horn.png'
import FAQ_S from '../../pdf/FAQ_S.pdf'
import ImportantInstr from '../../pdf/IMPORTANT INSTRUCTIONS FOR MOCK TEST.pdf'
import ImportantDates from '../../pdf/IMPORTANT INSTRUCTIONS FOR EXAMINATION.pdf'

import { Link } from 'react-router-dom'
function RightSideBar() {
    return (
        <div className='rightSidebarheader'>
            <div className="boxdesignNews" >



                <h5 className="newsHeading" style={{ display: 'flex' }}>
                    News &amp; Events
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <img src={horn} width="10%" />
                </h5>
                <nav id="sidebar">
                    <ul className="list-unstyled components">
                        {/* <li style={{ paddingBottom: '2px', listStyle: 'none', fontSize: '1rem' }}>
            <a style={{ fontSize: '0.8rem' ,display:'flex',justifyContent:'space-around'}} className="btn btn-primary btn-block btn-lg text-white"
                href="https://counselling.aicee.in/spot-login"> AICEE 2023 Counselling
                Portal(Spot Round New User) <sup><img src={newicon} alt='new' /></sup> </a>
                
        </li> */}


                        {/* <li style={{ paddingBottom: '2px', listStyle: 'none', fontSize: '1rem' }}>
            <a style={{ fontSize: '0.8rem' ,display:'flex',justifyContent:'space-around'}} className="btn btn-primary btn-block btn-lg text-white" href="#"
               > AICEE 2023 Counselling Portal(Registered Students) <sup><img src={newicon} alt='new' /></sup> </a>
            
        </li> */}
                        {/* <li style={{ paddingBottom: '2px', listStyle: 'none', fontSize: '1rem' }}>
            <a style={{ fontSize: '0.8rem' ,display:'flex',justifyContent:'space-around'}} className="btn btn-primary btn-block btn-lg text-white" href="https://onlineexam.aicee.in/"
                > AICEE 2023 Phase 2 Online Exam Link <sup><img src={newicon} alt='new' /></sup> </a>

        </li> */}
                        <li style={{ listStyle: 'none', marginTop: '5px' }}>
                            <a style={{ fontSize: '0.8rem', display: 'flex', justifyContent: 'space-around' }} className="btn btn-primary btn-block btn-lg text-white"
                                href="https://exam.aicee.in/"
                                // href='#'
                                target='_blank'
                            > AICEE 2024 Online MOCK EXAMINATION
                                <sup><img src={newicon} alt='new' /></sup> </a>

                        </li>
                        <li style={{ listStyle: 'none' }}>
                            <a style={{ fontSize: '14px', display: 'flex', justifyContent: 'space-around' }} className="btn btn-primary btn-block btn-lg text-white mt-1" href="https://onlineexam.aicee.in/" target='_blank'
                            > AICEE 2024 Main Examination
                                <sup><img src={newicon} alt='new' /></sup> </a>

                        </li>
                        <li style={{ listStyle: 'none' }}>
                            <a style={{ fontSize: '14px', display: 'flex', justifyContent: 'space-around' }} className="btn btn-primary btn-block btn-lg text-white mt-1" href={ImportantInstr} target='_blank'
                            > IMPORTANT INSTRUCTIONS FOR MOCK & MAIN EXAMINATION
                                <sup><img src={newicon} alt='new' /></sup> </a>

                        </li>
                        <li style={{ listStyle: 'none',display:'none' }}>
                            <a style={{ display:'hidden',fontSize: '14px', display: 'flex', justifyContent: 'space-around' }} className="btn btn-primary btn-block btn-lg text-white mt-1" href={ImportantDates} target='_blank'
                            > IMPORTANT INSTRUCTIONS FOR MOCK & MAIN EXAMINATION
                                <sup><img src={newicon} alt='new' /></sup> </a>

                        </li>

                        <li style={{ listStyle: 'none' }}>
                            <a style={{ fontSize: '14px', display: 'flex', justifyContent: 'space-around' }} className="btn btn-primary btn-block btn-lg text-white mt-1" href="https://counselling.aicee.in/" target='_blank'
                            > AICEE 2024 Counselling Portal 
                                <sup><img src={newicon} alt='new' /></sup>
                                 </a>

                        </li>
                        <li style={{ listStyle: 'none', textTransform: 'uppercase' }}>
                            <a style={{ fontSize: '0.8rem', display: 'flex', justifyContent: 'space-around' }} className="btn btn-primary btn-block btn-lg text-white mt-1" href="https://counselling.aicee.in/spot/student/login" target='_blank'
                            > AICEE 2023 Spot Round Counseling Portal
                                {/* <sup><img src={newicon} alt='new' /></sup>  */}
                                </a>

                        </li>


                        <li style={{ listStyle: 'none', marginTop: '3px' }}>
                            <a style={{ fontSize: '0.8rem', display: 'flex', justifyContent: 'space-around' }} className="btn btn-primary btn-block btn-lg text-white"
                                href="https://aicee.in/student/rank-card"
                                // href='#'
                                target='_blank'
                            > AICEE 2024 Result
                                {/* <sup><img src={newicon} alt='new' /></sup>  */}
                                </a>

                        </li>
                        <li style={{ listStyle: 'none' }}>
                            <span className="newspara" style={{ fontWeight: 'bold' }}>
                                <Link style={{ display: 'flex', justifyContent: 'space-around' }} to='/sample-question-paper-2023'>Download AICEE Sample Paper 2024 <sup><img src={newicon} alt='new' /></sup></Link>
                            </span>
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            <span className="newspara" style={{ fontWeight: 'bold' }}>
                                <a href={FAQ_S} target='_blank'> FREQUENTLY ASKED QUESTIONS (FAQs) AICEE-2024
                                </a>
                            </span>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className="boxdesignBottomLinks">
                <nav id="sidebar">
                    <ul className="list-unstyled components">
                        <li style={{ listStyle: 'none' }}>
                            <Link to="/institution/login" className="parentLink"> Institutions
                                Login </Link>
                        </li>
                        <li style={{ listStyle: 'none' }}>
                            <Link to="/contact" className="parentLink">Contact Now </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default RightSideBar 